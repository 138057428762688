import { Fragment, useState } from "react";
import Header from "./Header";
import MainWrapper from "./MainWrapper";
import SideNav from "./SideNav";
import CommonModal from "../CommonModal";
import { ModalContext } from "../../Context";
import { Route, Router, Routes, useNavigate } from "react-router-dom";

const Layout = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState("");
  const [modalSize, setModalSize] = useState("");
  const [data, setData] = useState({});

  const handleModalData = (data, size = "xl") => {
    setModalData(data);
    setModalSize(size);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <Fragment>
      <ModalContext.Provider
        value={{
          handleModalData,
          closeModal,
          setData,
          data,
          isModalOpen,
          modalData,
        }}
      >
        <div className="layout">
          <div className="side-nav">
            <SideNav />
          </div>

          <div className="page-container">
            <Header />

            <div className="body-container">
              <MainWrapper />
            </div>
          </div>
        </div>

        <CommonModal
          handleModalData={handleModalData}
          isModalOpen={isModalOpen}
          modalData={modalData}
          modalSize={modalSize}
          closeModal={closeModal}
        />
      </ModalContext.Provider>
    </Fragment>
  );
};

export default Layout;
