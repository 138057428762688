import UserForm from "./UserForm";
import PostDetails from "./PostDetails";
import RemoveConfirmation from "./RemoveConfirmation"
import modalTextsFile from "./modalTextsFile"
import ResetPassword from "./ResetPassword";
import GroupForm from "./FeedsForm";
import GroupPostAndPollComponent from "../Groups/AddgrouppostandPoll";
import AddNotificationComponent from "../Notification/AddNotificationCmp";

export const USER_FORM = <UserForm title="add new user" buttonText="add user"/>
export const INTERNATIONAL_OFFICER_USER_FORM = <UserForm title="add new international Officer" buttonText="add international Officer"/>
export const GROUP_FORM = (post, getFeedData) => <GroupForm getFeedData ={getFeedData} title="add new Group" post={post}/>
export const POST_MODAL = <PostDetails />
export const REMOVE_CONFIRMATION = <RemoveConfirmation title="REMOVE FEED" data={modalTextsFile.postRemoveText}/>
export const RESET_PASSWORD = <ResetPassword />
export const GROUP_ADD_POLL = (post, getFeedData) =>  <GroupPostAndPollComponent  getFeedData={getFeedData} />

export const  ADD_NOTIFICATION_DATA =  (post, getFeedData) =>  <AddNotificationComponent  getFeedData={getFeedData}/>
