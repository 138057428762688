import React, { useState, useMemo, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import HubDescription from "../Common/HubDescription";
import InnerTable from "../Common/InnerTable";
import { Button } from "@material-ui/core";
import { useLocation } from "react-router-dom";
import { miniHubsData, communityMember } from "./dummyData";
import { ModalContext } from "../../Context";
import MemberListing from "../ModalTemplate/MemberListing";
import plusIcon from "../../Assets/images/icons/plus.svg";
import AddMiniHub from "../ModalTemplate/AddMiniHub";
import HubsForm from "../ModalTemplate/HubsForm";
import RemoveConfirmation from "../ModalTemplate/RemoveConfirmation";
import modalTextsFile from "../ModalTemplate/modalTextsFile";
import { BASE_URL_IMG, deleteHub } from "../../Utils/services";
import { toast } from "react-toastify";

import {
  submitMiniHub,
  fetchMembers,
  getSingleHub,
  fetchMembersById,
  editHubApiHandler,
} from "./getRequests";
import EditHubsForm from "../ModalTemplate/EditHubsForm";

const HubDetailPage = () => {
  const [selectedMember, setSelectedMember] = useState([]);
  const [membersList, setMembersList] = useState([]);
  const [hubData, setHubData] = useState({});
  const [hubName, setHubName] = useState("");

  const navigate = useNavigate();
  const location = useLocation();
  const context = useContext(ModalContext);
  const { handleModalData, closeModal } = context;

  const addMember = () => {
    alert("added");
  };

  const handleHubPush = () => {
    navigate("-1");
  };

  useEffect(() => {
    fetchHubDetails();
  }, []);

  const fetchHubDetails = async () => {
    const { id, name } = location.state;
    setHubName(name);
    const res = await getSingleHub(id);
    const formateDate = formatDate(res.hub.createdOn);
    //console.log(res, id, 'singleHUb');
    setHubData({
      ...res,
      hub: {
        ...res.hub,
        formateDate,
      },
    });
  };

  const formatDate = (date) => {
    const _date = new Date(date);
    const month = _date.getMonth() + 1;
    const year = _date.getFullYear();
    const newdate = _date.getDate();
    return `${newdate}/${month}/${year}`;
  };

  // const EDIT_HUB = <HubsForm
  //   title="EDIT HUB DETAILS"
  //   btnText="SAVE"
  //   edit
  // />

  const EDIT_HUB = (
    <EditHubsForm
      title="EDIT HUB DETAILS"
      btnText="SAVE"
      id={hubData.hub && hubData.hub._id}
      hubData={hubData.hub}
      getMembers={fetchMembers}
      // getMembers={fetchMembersById}
      editHubApiHandler={editHubApiHandler}
      fetchHubDetails={fetchHubDetails}
    />
  );

  const MINI_HUBS_COMMUNITY_MEMBER = (
    <MemberListing
      data={communityMember}
      title="Community Members"
      selectedValue={selectedMember}
      setSelectedValue={setSelectedMember}
      optionsList={membersList}
      btnText="save"
      onClickHandler={addMember}
      helperText={
        "*start typing, the list will show up for multiple selects - Press enter to make an entry and then again start typing for selecting the second option*"
      }
    />
  );

  const handleModalForm = () => {
    const ADD_NEW_MINI_HUB = (
      <HubsForm
        title="ADD NEW MINI HUB"
        btnText="ADD MINI HUB"
        id={hubData.hub._id}
        getMembers={fetchMembersById}
        // getMembers={fetchMembers}
        submitHandler={submitMiniHub}
        refreshDataMethod={fetchHubDetails}
      />
    );

    handleModalData(ADD_NEW_MINI_HUB);
  };

  const editHub = () => {
    handleModalData(EDIT_HUB);
  };

  const _removeHub = async (id) => {
    try {
      const res = await deleteHub(id);
      navigate("/master");
      closeModal();
      toast.success(res.message);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const removeHub = () => {
    const REMOVE_HUB = (
      <RemoveConfirmation
        title="REMOVE HUB"
        action={_removeHub}
        id={hubData.hub._id}
        data={modalTextsFile.hubsRemoveText}
      />
    );
    handleModalData(REMOVE_HUB, "sm");
  };

  const goTo = (value) => {
    const { id } = location.state;
    const { _id, name } = value.row.original;
    navigate({
      pathname: "/master/hub/miniHub",
      state: { id: _id, name: name, hubId: id },
    });
  };

  const miniHubs = useMemo(
    () => [
      {
        Header: "Status",
        accessor: "col1", // accessor is the "key" in the data
        Cell: ({ cell: { value } }) =>
          value ? (
            <div className="rounded-full bg-green h-3 w-3"></div>
          ) : (
            <div className="rounded-full bg-orange h-3 w-3"></div>
          ),
      },

      {
        Header: "Logo",
        accessor: "logo",
        Cell: ({ cell: { value } }) => (
          <div className="flex justify-start items-center ">
            <img
              src={`${BASE_URL_IMG}${value}?${new Date().getTime()}`}
              alt=""
              className="icon "
            />
          </div>
        ),
      },

      {
        Header: "Mini Hub Name",
        accessor: "name",
        Cell: ({ cell: { value } }) => (
          <div className="flex justify-start items-center">
            <span> {value} </span>
          </div>
        ),
      },

      {
        Header: "Community Members",
        accessor: "members",
        Cell: ({ cell: { value } }) => (
          <div className="flex items-center">
            <span> {value.length} </span>
          </div>
        ),
      },
      {
        Header: "Mini Hub Abbreviation",
        accessor: "abbreviation",
      },

      {
        Header: "Created On",
        accessor: "createdOn",
        Cell: ({ cell: { value } }) => (
          <div className="flex justify-start items-center">
            <span> {formatDate(value)} </span>
          </div>
        ),
      },

      {
        id: "view",
        Header: "",
        accessor: "view",
        Cell: (props) => (
          <Button className="btn2" onClick={() => goTo(props)}>
            {" "}
            View{" "}
          </Button>
        ),
      },
    ],
    []
  );

  return (
    <>
      <div className="flex items-center mb-4 ml-10">
        <span
          onClick={handleHubPush}
          className="text-sm text-minihubPara cursor-pointer"
        >
          Hub
        </span>
        <span className="px-2 text-sm">/</span>
        <span className="text-sidenav capitalize text-sm">{hubName}</span>
      </div>

      <div className="grid grid-cols-9 gap-x-40 bg-white py-5">
        {hubData.hub && (
          <>
            <div className="col-span-3 sticky top-0 pl-10">
              <HubDescription
                data={hubData.hub}
                editHandler={editHub}
                removeHandler={removeHub}
                removeBtn="REMOVE HUB"
              />
            </div>

            <div className="col-span-6 sticky top-0">
              <div className="flex justify-between items-center">
                <h4 className="primary-color font-semibold"> Mini Hubs </h4>
                <ul className="flex items-center">
                  <li className="flex items-center">
                    <div className="rounded-full bg-green h-3 w-3 mr-3" />
                    Active
                    <span />
                  </li>

                  <li className="flex items-center ml-5">
                    <div className="rounded-full bg-inActive h-3 w-3 mr-3" />
                    Inactive
                    <span />
                  </li>
                </ul>

                <div
                  className="flex items-center cursor-pointer"
                  onClick={handleModalForm}
                >
                  <h4 className="primary-color font-semibold"> Add New </h4>
                  <img src={plusIcon} alt="" className="mx-4 w-8" />
                </div>
              </div>

              <InnerTable columns={miniHubs} data={hubData.minihubs} />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default HubDetailPage;
