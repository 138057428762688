import { Button, Switch, Tooltip } from "@material-ui/core";
import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { ModalContext } from "../../Context";
import { DeleteEvent, getMethodFunction } from "../../Utils/services";
import { GET_EVETN_BY_ID } from "../../Utils/routes";

import EditEventForm from "./EditEventForm";
import modalTextsFile from "./modalTextsFile";
import RemoveConfirmation from "./RemoveConfirmation";
import Loader from "../Common/Loader";
const formatDate = (date) => {
  const _date = new Date(date);
  const month = _date.getMonth() + 1;
  const year = _date.getFullYear();
  const newdate = _date.getDate();
  return `${newdate}/${month}/${year}`;
};
const EventEditDeletePopUp = ({
  title,
  buttonText,
  getEventApiData,
  VIEWEVENTDATA,
  eventDetails,
  eventId,
}) => {
  const context = useContext(ModalContext);
  const { handleModalData, closeModal } = context;
  const [year, setYear] = useState(new Date());
  const [month, setMonth] = useState(new Date());
  const [calendardata, setcaledardata] = useState();
  const [loader, setisloader] = useState(false);

  const perticularEventData = async (data) => {
    try {
      setisloader(true);
      const result = await getMethodFunction({
        UrlPath: `${GET_EVETN_BY_ID}/${eventDetails?._id}`,
      });

      setisloader(false);
      setcaledardata(result?.res);
    } catch (err) {
      setisloader(false);
      console.error(err);
    }
  };

  useEffect(() => {
    perticularEventData();
  }, []);

  const DisableEventHandler = async (data) => {
    console.log(data);
    try {
      const result = await getMethodFunction({
        UrlPath: `${GET_EVETN_BY_ID}/${data?._id}/toggle`,
      });

      console.log(result);

      setcaledardata();
      getEventApiData();
      closeModal();
    } catch (err) {
      console.error(err);
    }
  };
  return (
    <div className="warning-modal">
      <div className="flex items-center justify-center">
        <h3 className="title"> EVENT DETAILS</h3>
      </div>
      {/* {VIEWEVENTDATA.map((item, index) => ( */}
      {loader && (
        <div
          style={{
            marginLeft: "40%",
          }}
        >
          <Loader loader={loader} />
        </div>
      )}
      {calendardata && (
        <div
          className="max-w-lg w-full bg-white rounded-lg  mt-[0.5rem]"
          // key={item._id}
          // onClick={() => handleEditEventShow(item, item._id)}
        >
          <h3 className={`text-xl  text-[#184183]`}>{calendardata.title}</h3>
          <h3 className={`${"text-minihubPara mt-2"} `}>
            {calendardata.description}
          </h3>
          <div className="flex justify-between">
            <p className={`text-sm py-1 ${"text-[#184183]"}`}>
              {" "}
              {`${formatDate(calendardata.startdate)} to ${formatDate(
                calendardata.enddate
              )}`}{" "}
            </p>
            <p className={`text-sm ${"text-[#184183]"}`}>
              {/* {`${(item.startTime)} to ${(item.endTime)}`} */}
              {`${calendardata.starttime} to ${calendardata.endtime}`}
            </p>
          </div>

          <div className="flex justify-between">
            <p
              className={`text-sm py-1 ${
                calendardata.postTo == "li" ? "text-white" : "text-minihubPara"
              }`}
            >
              {calendardata.timezone}
            </p>
            <div>
              {" "}
              <Tooltip title="Disable Event" placement="bottom">
                <Switch
                  checked={calendardata?.Active}
                  onChange={() => DisableEventHandler(calendardata)}
                />
              </Tooltip>
            </div>
          </div>

          <p
            className={`text_tosmall_10 ${
              calendardata.postTo == "li" ? "text-white" : "text-minihubPara"
            }`}
          >
            {calendardata &&
              calendardata.links &&
              calendardata.links.map((item2, index) => {
                return (
                  <>
                    <div key={index}>
                      <p>{item2}</p>
                    </div>
                  </>
                );
              })}
          </p>
        </div>
      )}
      {/* ))} */}
    </div>
  );
};

export default EventEditDeletePopUp;
