import React, { useContext, useEffect } from "react";
import { ModalContext } from "../../Context";
import avatar from "../../Assets/images/avatar.png";
import { navlist } from "./navlist";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import classNames from "classnames";
import { useState } from "react";
import { BASE_URL_IMG, getAdminDetails } from "../../Utils/services";
import logo from "../../Assets/images/Curbside.png";

import { toast } from "react-toastify";
const CoolBox = ({ children, ...other }) => (
  <div
    style={
      {
        // borderRadius: "4px",
        // border: "2px #0074D9 solid",
        // margin: "10px",
        // padding: "10px",
      }
    }
    {...other}
  >
    {children}
  </div>
);
const SideNav = () => {
  const [adminData, setAdminData] = useState();
  const context = useContext(ModalContext);
  const location = useLocation();
  const navigate = useNavigate();

  const getAdminDetailsApiData = async () => {
    const id = localStorage.getItem("adminId");
    try {
      const result = await getAdminDetails({
        id: id,
        type: "admin",
      });
      if (result.success) {
        setAdminData(result.data);
      }
    } catch (error) {
      //console.log(error)
    }
  };

  useEffect(() => {
    // getAdminDetailsApiData();
  }, []);

  const navigateHandler = (path) => {
    navigate(`${path}`);
    // window.location.href = path;
    console.log(path);
  };

  return (
    <div id="sideNav">
      <div className="profile-info">
        <img src={logo} alt="avatar" className="w-2/3 rounded-full" />
        <h3
          className="title"
          onClick={() => {
            navigate("/user");
          }}
        >
          {" "}
          {adminData && adminData.name}{" "}
        </h3>
        <h6 className="sub-title">
          {/* <Link to="/profile">Manage Account</Link>s */}
          {/* <div
            onClick={(e) => {
              e.stopPropagation();
              navigate("/user");
            }}
          >
            Hello
          </div> */}
        </h6>
      </div>

      <div className="navlist">
        <ul>
          {navlist.map((item, id) => {
            let path = item.path;

            return (
              <React.Fragment key={id}>
                <li key={id}>
                  <CoolBox data-tutorial-id={`${id}`}>
                    <Link
                      to={`${path}`}
                      onClick={(e) => {
                        e.stopPropagation();
                        navigateHandler(path);
                      }}
                      className={classNames({
                        "flex items-center justify-center cursor-pointer ": true,
                        "opacity-100": location.pathname === item.path,
                        "opacity-50": location.pathname !== item.path,
                      })}
                    >
                      <div className="grid grid-cols-6 gap-4 items-center">
                        <span className="col-span-2">
                          <img src={item.icon} alt="icon" className="h1p5" />
                        </span>
                        <span className="uppercase">{item.label}</span>
                      </div>
                    </Link>
                  </CoolBox>
                </li>
              </React.Fragment>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default SideNav;
