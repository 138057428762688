import React, { useState, useContext, Fragment } from "react";
import { USER_FORM, INTERNATIONAL_OFFICER_USER_FORM } from "../ModalTemplate";
import { TabPanel } from "../Common/TabPanel";
import InnerHeader from "../Common/InnerHeader";
import ManageTab from "./ManageTab";
import { ModalContext } from "../../Context";
import DisabledTab from "./DisabledTab";

const a11yProps = (index) => {
  return {
    id: `flagged-tab-${index}`,
    "aria-controls": `flagged-tabpanel-${index}`,
  };
};

const Flagged = () => {
  const [tabsValue, setTabsValue] = useState(0);

  const handlerTabs = (event, newValue) => {
    setTabsValue(newValue);
  };

  const tabsData = [
    {
      label: "Reported",
      data: <ManageTab />,
    },
    {
      label: "Disabled",
      data: <DisabledTab />,
    },
  ];

  return (
    <Fragment>
      <div className="relative">
        <InnerHeader
          tabsData={tabsData}
          tabsValue={tabsValue}
          handlerTabs={handlerTabs}
          a11yProps={a11yProps}
        />

        {tabsData.map((obj, index) => (
          <TabPanel
            key={index}
            className="px-10"
            value={tabsValue}
            index={index}
          >
            {obj.data}
          </TabPanel>
        ))}
      </div>
    </Fragment>
  );
};

export default Flagged;
