import { useContext, useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import AntSwitch from '../Common/AntSwitch'
import { RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { addUser, getExpertise, getHubs, partyList, regionCountryList } from '../../Utils/services';
import { toast } from 'react-toastify';
import { ModalContext } from '../../Context';
import Loader from "../Common/Loader"


const _helperText = {
    badge: "*start typing, the list will show up for multiple selects - Press enter to make an entry and then again start typing for selecting the second option",
    expertise: "*start typing, the list will show up for multiple selects - Press enter to make an entry and then again start typing for selecting the second option"
}


const UserForm = ({
    title,
    buttonText,
    getTablesData
}) => {
    const [isDirectory, setIsDirectory] = useState(true);
    const [loader, setLoader] = useState(false);
    const [partyListData, setPartyListData] = useState([]);
    const [badges, setBadges] = useState([]);
    const [fileName, setFileName] = useState('');
    const [formData, setFormData] = useState({});
    const [isInternational, setIsInternational] = useState(false)
    const [party, setParty] = useState([]);
    const [expertiseList, setExpertiseList] = useState([]);
    const [expertise, setExpertise] = useState([]);
    const [allFieldsData, setAllFieldsData] = useState({
        members: [],
    })
    const [region, setRegion] = useState('');
    const [regions, setRegions] = useState([]);
    const [country, setCountry] = useState('');
    const [countries, setCountries] = useState([]);

    const getCountryData = async () => {
        try {
            const result = await regionCountryList();
            if (result.res.data.country.length > 0 && result.res.data.region.length > 0) {
                //console.log(result)
                // const regionData = [];
                // result.res.data.region.map((obj) => {
                //     regionData.push(obj.name);
                // })
                // setRegions(regionData);
                setRegions(result.res.data.region);
                // setCountries(result.res.data.country);
            }
        }
        catch (error) {
            //console.log(error);
            
        }
    }

    useEffect(() => {
        getCountryData();
    }, []);

    // const handleRegionData = (val) => {  //this is old for reference
    //     setRegion(val);
    //     const countriesData = countries.filter(obj => obj.region.toLowerCase() == val.toLowerCase());
    //     //console.log(countriesData, "countriesData");
    //     setCountries(countriesData);
    // }
    const handleRegionData = async (val) => {
        setRegion(val);
        try {
            const result = await regionCountryList();
            const _allCountry = result.res.data.country;
            const countriesData = _allCountry.filter(obj => {
                return obj.region.toLowerCase() == val.toLowerCase()
            }
            )
            setCountries(countriesData);
        }
        catch (error) {
            //console.log(error);
            
        }
    }

    const context = useContext(ModalContext);
    const { closeModal } = context;

    const onInputChange = (event, newInputValue, name) => {
        const _name = event.target.name
        const _value = event.target.value

        if (name == 'members') {
            setAllFieldsData({
                ...allFieldsData,
                [name]: newInputValue
            })
        }
        else {
            setAllFieldsData({
                ...allFieldsData,
                [_name]: _value
            })
        }
    }
    const onInputPartyChange = (event, newInputValue, name) => {
        const _name = event.target.name
        const _value = event.target.value

        if (name == 'party') {
            setParty({
                ...party,
                [name]: newInputValue
            })
        }
        else {
            setAllFieldsData({
                ...allFieldsData,
                [_name]: _value
            })
        }
    }
    const onInputExpertiseChange = (event, newInputValue, name) => {
        const _name = event.target.name
        const _value = event.target.value

        if (name == 'expertise') {
            setExpertise({
                ...expertise,
                [name]: newInputValue
            })
        }
        else {
            setExpertise({
                ...expertise,
                [_name]: _value
            })
        }
    }

    const onChangeHandler = e => {
        e.preventDefault()
        const name = e.target.name
        const value = e.target.value

        setFormData({
            ...formData,
            [name]: value
        })
    }

    const handleChange = (event) => {
        setIsDirectory(!isDirectory)
    };

    const handleFile = (val) => {
        if (!!val) {
            let img = new Image();
            img.src = window.URL.createObjectURL(val);

            img.onload = () => {
                if (img.width === 400 && img.height === 400) {
                    setFileName(val);
                }
                else {
                    setFileName("");
                }
            }
        }
    }

    const getData = async () => {
        try {
            const result2 = await getHubs();
            const _HubList = result2.res;
            setBadges(_HubList)
        }
        catch (error) {
            
        }
    }
    const getExpertiseData = async () => {
        try {
            const result2 = await getExpertise();
            const _ExpertiseList = result2.res;
            setExpertiseList(_ExpertiseList)
        }
        catch (error) {
            
        }
    }
    const getParty = async () => {
        try {
            const response = await partyList();
            const _partyList = response.res.data.party;
            setPartyListData(_partyList)

        }
        catch (error) {
            
        }
    }

    useEffect(() => {
        getData();
        getExpertiseData();
    }, [])

    useEffect(() => {
        getParty();
    }, [])

    useEffect(() => {
        if (title === "add new international Officer") {
            setIsInternational(true)
        }
    }, [title])

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoader(true);
        const addFormData = new FormData();
        if (allFieldsData.members) {
            const badgesValueSend = allFieldsData && allFieldsData.members.map(data => {
                return data._id
            })
            addFormData.append("badge", badgesValueSend);
        }
        if (expertise.expertise) {
            const ExpertiseValueSend = expertise && expertise.expertise.map(data => {
                return data.area
            })
            addFormData.append("expertise", ExpertiseValueSend);
        }
        addFormData.append("name", formData.name);
        addFormData.append("email", formData.email && formData.email.toLowerCase());
        addFormData.append("designation", formData.designation);
        addFormData.append("media", fileName);
        addFormData.append("region", region);
        addFormData.append("country", country);
        if (party.party !== undefined) {
            addFormData.append("party", party.party._id);
        }
        addFormData.append("isInternational", isInternational);
        addFormData.append("access", isDirectory);
        try {
            const results = await addUser(addFormData);
            if (results.res.success) {
                toast.success(results.res.message);
                setLoader(false);
                closeModal();
                getTablesData();
            }
        }
        catch (e) {
            setLoader(false);
            toast.error(e.messages ?? e.message);
        }
    }
    //console.log(expertiseList, "expertiseList Data")


    function dynamicSort(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    }

    return (
        <>
            <Loader loader={loader} />
            <div className="flex items-center justify-center">
                <h3 className="title"> {title} </h3>
            </div>
            <form onSubmit={handleSubmit} autoComplete="off" className="w-full">
                <div className="grid grid-cols-2 gap-x-24 gap-y-4">
                    <TextField
                        id="name"
                        label="Name"
                        name="name"
                        value={formData.name || ''}
                        onChange={e => onChangeHandler(e)}
                        required
                    />
                    <TextField
                        id="email"
                        label="Email"
                        name="email"
                        value={formData.email || ''}
                        onChange={e => onChangeHandler(e)}
                        required
                    />
                    <TextField
                        id="designation"
                        label="Title"
                        name="designation"
                        value={formData.designation || ''}
                        onChange={e => onChangeHandler(e)}
                    />
                    <div>
                        <div className="relative w-auto overflow-hidden">
                            <TextField
                                id="profilephoto"
                                label="Add a Profile Photo"
                                value={fileName.name || ""}
                                className="w-full text-xs"
                            />

                            <input
                                type="file"
                                accept="image/*"
                                className="z-50 opacity-0 absolute bottom-0 right-0 w-50"
                                name="profile-photo"
                                onChange={(e) => handleFile(e.target.files[0])}
                            />
                            <div className="floating-box" >+</div>
                        </div>
                        <p className="text-sm text-red-800 mt-2">Note: Image dimensions should be 400x400px </p>
                    </div>

                    <Autocomplete
                        id="combo-box-demo1"
                        options={regions && regions.sort(dynamicSort("name"))}
                        getOptionLabel={(option) => option.name}
                        sx={{ width: 300 }}
                        inputValue={region}
                        onInputChange={(event, newInputValue) => handleRegionData(newInputValue)}
                        renderInput={(params) => <TextField required {...params} label="Region" />}
                        
                    />
                    {region && <Autocomplete
                        id="combo-box-demo2"
                        options={countries && countries.sort(dynamicSort("name"))}
                        getOptionLabel={(option) => option.name}
                        sx={{ width: 300 }}
                        inputValue={country}
                        onInputChange={(event, newInputValue) => setCountry(newInputValue)}
                        renderInput={(params) => <TextField  {...params} label="Country" />}
                    />}

                    <Autocomplete
                        name="party"
                        options={partyListData && partyListData.sort(dynamicSort("name"))}
                        getOptionLabel={(option) => option.name}
                        value={party.name}
                        onChange={(e, newVal) => onInputPartyChange(e, newVal, 'party')}
                        renderInput={(params) => <TextField required {...params} id="party" label="Name of the Party/Organisation/Thinktank" />}
                    />
                    <Autocomplete
                        multiple
                        name="members"
                        limitTags={2}
                        options={badges && badges.sort(dynamicSort("name"))}
                        getOptionLabel={(option) => option.name}
                        value={allFieldsData.members}
                        onChange={(e, newVal) => onInputChange(e, newVal, 'members')}
                        renderInput={(params) => <TextField  {...params} id="badge" label="Add a Badge" helperText={_helperText.badge} />}
                    />
                    <Autocomplete
                        multiple
                        name="expertise"
                        limitTags={2}
                        options={expertiseList && expertiseList.sort(dynamicSort("area"))}
                        getOptionLabel={(option) => option.area}
                        value={expertise.area}
                        onChange={(e, newVal) => onInputExpertiseChange(e, newVal, 'expertise')}
                        renderInput={(params) => <TextField {...params} id="expertise" label="Add Expertise" helperText={_helperText.expertise} />}
                    />
                    <div className="mt-4 w-5/12">
                        <Grid component="label" container alignItems="center" spacing={1}>
                            <Grid item> Access to Directory </Grid>
                            <Grid item>
                                <AntSwitch
                                    checked={isDirectory}
                                    onChange={handleChange}
                                    name="isDirectory"
                                />
                            </Grid>
                        </Grid>
                    </div>
                </div>

                <div className="flex justify-center mt-10">
                    <button className="button-1 mx-auto" disabled={loader ? true : false}>
                        {buttonText}
                    </button>
                </div>
            </form>
        </>
    )
}

export default UserForm;