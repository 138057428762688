import UsersTable from "./UsersTable";
import { useContext, useEffect, useMemo, useState } from "react";
import AntSwitch from "../Common/AntSwitch";
import { makeStyles } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";
import { Button, Grid } from "@material-ui/core";
import { ModalContext } from "../../Context";
import UserEditForm from "../ModalTemplate/UserEditForm";
import {
  BASE_URL_IMG,
  InviteAllPeopleNotification,
  InviteNotification,
  userDeactivate,
} from "../../Utils/services";
import { toast } from "react-toastify";
import MaintenanceModeConfirmation from "../ModalTemplate/MaintenanceModeConfirmation";
import modalTextsFile from "../ModalTemplate/modalTextsFile";
import DummyDpImg from "../../Assets/images/DummyDp.png";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      marginTop: theme.spacing(2),
    },
  },
}));

const AllUsers = ({ data, getTablesData }) => {
  useEffect(() => {
    getTablesData();
  }, []);

  const userColumns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        Cell: ({ row }) => (
          <div className="flex gap-2 text-center align-middle items-center">
            <img
              src={`${BASE_URL_IMG}/uploads/profile/${row?._id}/${row?._id}.jpeg`}
              className="h-[2rem] min-h-[2rem] min-w-[2rem]"
              alt=""
              loading="lazy"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = `data:image/svg+xml;base64,${btoa(`
      <svg xmlns="http://www.w3.org/2000/svg" width="2rem" height="2rem"  viewBox="0 0 100 100">
      <rect width="100%" height="100%" fill="#FCAD73" rx="50%" ry="50%" />
        <text x="50%" y="50%" font-size="40" text-anchor="middle" dominant-baseline="middle" fill="#52575d" font-family="GillSansMedium">
        ${
          row?.original?.firstname
            ? `${row?.original?.firstname?.charAt(
                0
              )}${row?.original?.lastname?.charAt(0)}`
            : "N"
        }

        </text>
      </svg>
    `)}`;
              }}
            />
            <div className="items-center">{`${
              row?.original?.firstname ? row?.original?.firstname : ""
            }  ${row?.original?.lastname ? row?.original?.lastname : ""}`}</div>
          </div>
        ),
      },
      {
        Header: "Email",
        accessor: "email",
        Cell: ({ row }) => <div>{row.original.email}</div>,
      },
      {
        Header: "Phone",
        accessor: "mobile",
        Cell: ({ row }) => <div>{row.original.mobile}</div>,
      },
      {
        Header: "Status",
        accessor: "status",
        // Cell: ({ row }) => <div>{row.original.mobile}</div>, // Ensure correct rendering
      },
    ],
    []
  );

  return <div>{data && <UsersTable columns={userColumns} data={data} />}</div>;
};

export default AllUsers;
