import { useState, useContext, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import {
  addCountry,
  BASE_URL_IMG,
  deleteCountry,
  editCountry,
  postMethodFunction,
  regionCountryList,
} from "../../Utils/services";
import { toast } from "react-toastify";
import { ModalContext } from "../../Context";
import { getCountriesData } from "../Master/getRequests";
import RemoveConfirmation from "./RemoveConfirmation";
import modalTextsFile from "./modalTextsFile";
import { Button } from "@material-ui/core";
import Loader from "../Common/Loader";
import { Autocomplete } from "@material-ui/lab";
import { PROMP_API_URL } from "../../Utils/routes";
import SmallSpinner from "../Common/SmallSpinner";

const AddPrompt = ({
  title,
  SETPROMPTDATA,
  btnText,
  edit,
  id,
  name,
  region,
  image,
}) => {
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState({});
  const [regionVal, setRegionVal] = useState([]);

  const context = useContext(ModalContext);
  const { closeModal, handleModalData } = context;

  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setData({ ...data, [name]: value });
  };

  const handleAdd = async (e) => {
    e.preventDefault();
    setLoader(true);
    const apidata = {
      promptName: data.promp_name,
    };
    try {
      const result = await postMethodFunction({
        URLPATH: PROMP_API_URL,
        DATA: apidata,
        TYPE: "body",
      });
      if (result.res) {
        toast.success(result.res.message);
        SETPROMPTDATA({ promptName: data.promp_name, _id: result?.data?._id });
        setLoader(false);
        closeModal();
      }
    } catch (e) {
      setLoader(false);
      console.log(e);
      toast.error(e.messages ?? e.message);
    }
  };

  return (
    <>
      <div className="flex items-center justify-center">
        <h3 className="title"> {title} </h3>
      </div>

      <form
        onSubmit={handleAdd}
        autoComplete="off"
        className="w-full"
      >
        <div className="flex flex-col w-full">
          <div className="mb-4">
            <TextField
              required
              id="name"
              label="Prompt Name"
              className="w-full"
              name="promp_name"
              onChange={(e) => handleChange(e)}
              value={data.promp_name}
            />
          </div>
        </div>

        <div className="flex justify-center mt-10">
        
            <button
              className="button-1 flex gap-2 mx-auto"
              disabled={loader ? true : false}
            >
              <SmallSpinner Loading={loader} />   {btnText}
            </button>
          
        </div>
      </form>
    </>
  );
};

export default AddPrompt;
