import PostWrapper from "./PostWrapper";
import react, { useEffect, useState } from "react";
import { fetchManageFlag, getMethodFunction } from "../../Utils/services";
import { toast } from "react-toastify";
import { FLAGGED_API, DISABLE_SPAM_POST } from "../../Utils/routes";
import { Switch } from "@material-ui/core";

const ManageTab = () => {
  const [flagData, setFlagData] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const result = await getMethodFunction({
          UrlPath: FLAGGED_API,
        });

        setFlagData(result?.res);
      } catch (err) {
        toast.error(err.message);
      }
    })();
  }, []);
  const getManageFlag = async () => {
    try {
      const res = await fetchManageFlag();
      //console.log(res);
      setFlagData(res.data);
      toast.success(res.message);
    } catch (error) {
      toast.success(error.message);
    }
  };

  const handleChange = async (data, dataindex) => {
    console.log(data);
    console.log(dataindex);

    try {
      const result = await getMethodFunction({
        UrlPath: `${DISABLE_SPAM_POST}/${data?.Post?._id}`,
      });
      console.log(result);
      if (result?.res) {
        toast.success("Successfully Disable");
        setFlagData((prev) =>
          prev?.filter((item, index) => index !== dataindex)
        );
      }
    } catch (err) {
      toast.error(err);
    }
  };
  return (
    <>
      {flagData.length > 0 && (
        <div className="custom-table bg-white" id="manage-tab">
          <ul className="head sticky top-0">
            <li> Post </li>
            <li> Reported By </li>
            {/* <li> Reported On </li> */}
            <li> Issue </li>
            <li> Created By </li>
            <li> Status </li>
          </ul>

          {flagData?.length === 0 ? (
            <div className="flex justify-center p-10">Sorry No Data Found</div>
          ) : (
            flagData.map((item, index) => (
              <ul className="head bg_none_imp flex">
                <li key={index}>
                  <span>{item?.Post?.title}</span>
                </li>
                <li key={index}>
                  <span>
                    {item?.ReportedBy?.firstname} {item?.ReportedBy?.lastname}
                  </span>
                </li>

                <li key={index}>
                  <span>{item?.ReportedBy?.specification?.[0]}</span>
                </li>
                <li key={index}>
                  {item?.postCreatedBy?.firstname}{" "}
                  {item?.postCreatedBy?.lastname}
                </li>
                <li key={index}>
                  <>
                    <Switch
                      checked={true}
                      onChange={() => handleChange(item, index)}
                    />
                  </>
                </li>
              </ul>
            ))
          )}

          {/* <div className="body">
            {flagData.map((item, indx) => (
              <PostWrapper
                data={item}
                key={indx}
                reRenderFlags={getManageFlag}
              />
            ))}
          </div> */}
        </div>
      )}
    </>
  );
};

export default ManageTab;
