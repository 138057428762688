import React, { Fragment, useContext, useState } from "react";
import { toast } from "react-toastify";
import { ModalContext } from "../../Context";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { postMethodFunction } from "../../Utils/services";
import { ADD_NOTIFICATION_API } from "../../Utils/routes";
const AddNotificationComponent = ({ getFeedData }) => {
  const [formData, setFormData] = useState({});
  const [loader, setLoader] = useState(false);
  const [specilaitydata, setspecialitydata] = useState();
  const context = useContext(ModalContext);
  const { closeModal } = context;
  const onChangeHandler = (e) => {
    e.preventDefault();
    const name = e.target.name;
    const value = e.target.value;
    setFormData({ ...formData, [name]: value });
  };

  console.log(specilaitydata?.imagedata);

  const handlePostSubmit = async (e) => {
    setLoader(true);

    e.preventDefault();

    const postFormData = new FormData();
    postFormData.append("title", formData.title ? formData.title : "");
    postFormData.append(
      "description",
      formData.description ? formData.description : ""
    );
    postFormData.append("image", specilaitydata?.imagedata);

    try {
      const result = await postMethodFunction({
        URLPATH: ADD_NOTIFICATION_API,
        DATA: postFormData,
        TYPE: "body",
        CONTENTYPE: "multipart/form-data",

      });
      console.log(result);
      if (result.status) {
        toast.success(result?.message);
        closeModal();
        getFeedData(result?.data);
        setFormData({});
        setspecialitydata();
        setLoader(false);
        closeModal();
        //console.log(result.res, "This is result response")
      }
    } catch (error) {
      setLoader(false);
      //console.log(error);
      toast.error(error.message);
    }
  };
  return (
    <Fragment>
      <>
        <div className="flex items-center justify-center">
          <h3 className="title">Add New Notification</h3>
        </div>

        <form
          noValidate
          autoComplete="off"
          className="w-full"
          onSubmit={handlePostSubmit}
        >
          <div className="grid grid-cols-2 gap-x-24 gap-y-4">
            <div>
              <TextField
                name="title"
                value={formData?.title || ""}
                onChange={(e) => onChangeHandler(e)}
                id="title"
                label="Title"
                className="w-full"
              />
            </div>
            <div>
              <TextField
                name="description"
                value={formData?.description || ""}
                onChange={(e) => onChangeHandler(e)}
                id="description"
                label="Description"
                className="w-full"
              />
            </div>

            <div className="relative w-auto overflow-hidden">
              <TextField
                id="profilephoto"
                label="Add Image"
                value={specilaitydata?.imagedata?.name || ""}
                className="w-full text-xs"
              />

              <input
                type="file"
                accept="image/*"
                className="z-50 opacity-0 absolute bottom-0 right-0 w-50"
                name="image-file"
                onChange={(e) =>
                  setspecialitydata((prev) => ({
                    ...prev,
                    imagedata: e.target.files[0],
                  }))
                }
              />
              <div className="floating-box">+</div>
            </div>
          </div>

          <div className="flex justify-center mt-10">
            <button
              className="button-1 mx-auto"
              disabled={loader ? true : false}
            >
              Add NOtification
            </button>
          </div>
        </form>
      </>
    </Fragment>
  );
};

export default AddNotificationComponent;
