import React, { useState, useContext, useEffect } from "react";
import { TabPanel } from "../Common/TabPanel";
import InnerHeader from "../Common/InnerHeader";
import { ModalContext } from "../../Context";
import HubsForm from "../ModalTemplate/HubsForm";
import AddRegion from "../ModalTemplate/AddRegion";
import AddCountry from "../ModalTemplate/Addprompt";
import AddExpertise from "../ModalTemplate/AddExpertise";
import Expertise from "./Expertise/Expertise";
import Affiliation from "./Affiliation";
import AffiliationForm from "../ModalTemplate/AffiliationForm";
import { submitHub, fetchMembers } from "./getRequests";
import { getHubs, getMethodFunction, postMethodFunction } from "../../Utils/services";
import { toast } from "react-toastify";
import Loader from "../Common/Loader";
import { ADD_DESIGNATION, SPECIALITY_ROUTES } from "../../Utils/routes";
import DesignationRoutinganddata from "./Designationroutingandata";
import ExperienceBodyComponent from "./Experience/ExperienceComponent";
import PROMPTDATACOMPONENT from "./Prompt/Promptcmp";
import AddPrompt from "../ModalTemplate/Addprompt";

const a11yProps = (index) => {
  return {
    id: `users-tab-${index}`,
    "aria-controls": `users-tabpanel-${index}`,
  };
};

const Master = () => {
  const [tabsValue, setTabsValue] = useState(0);
  const [expertiseData, setExpertiseData] = useState([]);
  const [countryData, setCountryData] = useState([]);
  const [designationvaluedata, setdesignationvaluedata] = useState([]);
  const [promptdata, setpromptdata] = useState();
  const [regionData, setRegionData] = useState([]);
  const [affiliationData, setAffiliationData] = useState([]);
  const [hubsList, setHubsList] = useState([]);
  const [loader, setLoader] = useState(false);

  const context = useContext(ModalContext);
  const { handleModalData } = context;

  const handlerTabs = (event, newValue) => {
    setTabsValue(newValue);
  };

  const getHubsData = async () => {
    setLoader(true);
    try {
      const result = await getHubs();
      setLoader(false);
      const _hubList = result.res;
      setHubsList(_hubList);
    } catch (e) {
      setLoader(false);
      toast.error(e.message);
    }
  };

  const ADD_NEW_HUB = (
    <HubsForm
      title="Add New Designation"
      btnText="ADD Designation"
      // getMembers={fetchMembers}
      submitHandler={async (data) => {
        try {
          const result = await postMethodFunction({
            URLPATH: ADD_DESIGNATION,
            DATA: data,
            TYPE: "body",
          });

          setdesignationvaluedata((prev) => [result.res.data, ...prev]);
        } catch (err) {
          console.log(err);
        }
      }}
      // setData={setHubsList}
      // refreshDataMethod={getHubsData}
    />
  );

  const ADD_REGION = (
    <AddRegion
      setRegionData={setRegionData}
      title="Add New Experience"
      btnText="ADD Experience"
    />
  );

  const ADD_PROMPT = (
    <AddPrompt
      SETPROMPTDATA={(data) => {
        console.log(data);
        setpromptdata((prev) => [...prev, data]);
      }}
      title="Add New Prompt"
      btnText="ADD Prompt"
    />
  );

  const ADD_AFFILIATION = (
    <AffiliationForm
      title="ADD SPECIALITY"
      setAffiliationData={async () => {
        try {
          const result = await getMethodFunction({
            UrlPath: SPECIALITY_ROUTES,
          });
          console.log(result);

          setAffiliationData(result?.res);
        } catch (err) {}
      }}
    />
  );

  const ADD_EXPERTISE = (
    <AddExpertise
      title="ADD EXPERTISE"
      btnText="ADD EXPERTISE"
      setExpertiseData={setExpertiseData}
    />
  );

  const handleModalForm = () => {
    switch (tabsValue) {
      case 0:
        handleModalData(ADD_NEW_HUB);
        break;

      case 1:
        handleModalData(ADD_REGION, "md");
        break;

      case 2:
        handleModalData(ADD_PROMPT, "md");
        break;

      case 3:
        handleModalData(ADD_AFFILIATION);
        break;

      case 4:
        handleModalData(ADD_EXPERTISE, "md");
        break;

      default:
        handleModalData(ADD_NEW_HUB);
        break;
    }
  };

  const tabsData = [
    {
      label: "Designation",
      // data: <HubsRouting hubsList={hubsList} setHubsList={setHubsList} />,
      data: (
        <DesignationRoutinganddata
          designationdata={designationvaluedata}
          setdesignationdata={setdesignationvaluedata}
        />
      ),
    },

    {
      label: "Experience",
      data: <ExperienceBodyComponent />,
    },

    {
      label: "Prompts",
      data: (
        <PROMPTDATACOMPONENT
          SETPROMPTDATA={setpromptdata}
          PROMPTDATA={promptdata}
        />
      ),
    },

    {
      label: "Speciality",
      data: (
        <Affiliation
          setAffiliationData={setAffiliationData}
          affiliationData={affiliationData}
        />
      ),
    },

    // {
    //   label: "area of expertise",
    //   data: (
    //     <Expertise
    //       setExpertiseData={setExpertiseData}
    //       expertiseData={expertiseData}
    //     />
    //   ),
    // },
  ];

  return (
    <>
      <Loader loader={loader} />
      <div className="relative">
        <InnerHeader
          tabsData={tabsData}
          tabsValue={tabsValue}
          handlerTabs={handlerTabs}
          a11yProps={a11yProps}
          handleModalForm={handleModalForm}
          formBtn
        />

        {tabsData.map((obj, index) => (
          <TabPanel
            key={index}
            className="px-10"
            value={tabsValue}
            index={index}
          >
            {obj.data}
          </TabPanel>
        ))}
      </div>
    </>
  );
};
export default Master;
