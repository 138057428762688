import React, { Fragment, useContext, useRef, useState } from "react";
import { ModalContext } from "../../Context";
import TextField from "@material-ui/core/TextField";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { useLocation } from "react-router-dom";
import { postMethodFunction } from "../../Utils/services";
import { ADD_GROUP_POLL, ADD_GROUP_POST } from "../../Utils/routes";
import { toast } from "react-toastify";
const GroupPostAndPollComponent = ({ getFeedData }) => {
  const context = useContext(ModalContext);
  const { closeModal } = context;
  const [tab, settab] = useState(1);
  const [formData, setFormData] = useState({ postTo: "li" });
  const [loader, setLoader] = useState(false);
  const [numberofpoll, sernumberofpoll] = useState([0]);
  const [polldetails, setpolldetials] = useState([]);

  const { pathname } = useLocation();

  const [cropperfile, setcropperfile] = useState();
  const [isuploaded, setisuploaded] = useState(false);
  const [initialfile, setinitilafile] = useState(false);

  const onChangeHandler = (e) => {
    e.preventDefault();
    const name = e.target.name;
    const value = e.target.value;
    setFormData({ ...formData, [name]: value });
  };
  const cropperRef = useRef(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    setinitilafile(file);
    setisuploaded(true);
    reader.onload = () => {
      if (cropperRef.current && reader.result) {
        // Load the uploaded image into the Cropper
        cropperRef.current.cropper.replace(reader.result);
      }
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const onCrop = () => {
    if (cropperRef.current) {
      const cropper = cropperRef.current.cropper;
      const canvas = cropper.getCroppedCanvas();
      if (canvas) {
        canvas.toBlob((blob) => {
          const croppedFile = new File([blob], initialfile?.name, {
            type: initialfile?.type,
          });
          setcropperfile(croppedFile);
        }, initialfile?.type);
      }
    }
  };

  const handlePostSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    try {
      const postFormData = new FormData();
      postFormData?.append(
        "postedas",
        formData?.postTo === "li" ? "Name" : "Admin"
      );
      postFormData?.append("postedastype", formData?.postTo === "li" ? 0 : 1);
      postFormData?.append("title", formData?.title);
      postFormData?.append("description", formData?.description);
      postFormData?.append("visibleto", 1);
      postFormData?.append("groupid", pathname?.split("/").pop());
      postFormData.append("images", cropperfile);

      const postformdatabodyformat = {
        postedas: formData?.postTo === "li" ? "Name" : "Admin",
        postedastype: formData?.postTo === "li" ? 0 : 1,
        title: formData?.title,
        visibleto: 1,
        groupid: pathname?.split("/").pop(),
        images: cropperfile,
        options: polldetails?.map((item, index) => ({
          id: index,
          label: item?.Poll || "",
          value: index,
        })),
      };

      let result;

      if (tab === 1) {
        result = await postMethodFunction({
          URLPATH: ADD_GROUP_POST,
          DATA: postFormData,
          TYPE: "body",
          CONTENTYPE: "multipart/form-data",
        });
      } else if (tab === 2) {
        result = await postMethodFunction({
          URLPATH: ADD_GROUP_POLL,
          DATA: postformdatabodyformat,
          TYPE: "body",
          CONTENTYPE: "application/json",
        });
      }

      console.log(result);
      if (result?.message) {
        toast.success(result?.message);
        setFormData({
          postTo: "li",
        });
        getFeedData();
        setcropperfile();
        setisuploaded(false);
        setLoader(false);
        setinitilafile();
        closeModal();
      }else if (result?.res?.message){
        toast.success(result?.res?.message);
        setFormData({
          postTo: "li",
        });
        getFeedData();
        setcropperfile();
        setisuploaded(false);
        setLoader(false);
        setinitilafile();
        closeModal();
      }
    } catch (err) {
      setLoader(false);

      toast.error(err?.message);
      console.error(err);
    }
  };

  console.log(polldetails);

  return (
    <Fragment>
      <div className="flex items-center justify-center">
        <h3 className="title">Add Group Insights</h3>
      </div>
      <div className="flex gap-2 ">
        <button
          className={`tab_button-1   ${
            tab === 1 ? "bg-[#FA7714]" : "bg-gray-500"
          }`}
          onClick={() => settab(1)}
        >
          {" "}
          Post
        </button>
        <button
          className={`tab_button-1 ${
            tab === 2 ? "bg-[#FA7714]" : "bg-gray-500"
          }`}
          onClick={() => settab(2)}
        >
          Poll
        </button>
      </div>

      <form
        noValidate
        autoComplete="off"
        className="w-full"
        onSubmit={handlePostSubmit}
      >
        <div className="grid grid-cols-2 gap-x-24 gap-y-4">
          <div className="mt-4 w-6/12 flex  items-center">
            <h1 className="text-paraText min-w-[100px]">Post As</h1>
            <RadioGroup
              aria-label="event"
              defaultValue="LI Calendar"
              name="postTo"
              className="radio-group"
              value={formData.postTo}
              onChange={onChangeHandler}
            >
              <span className="ml-8 flex">
                <FormControlLabel
                  value="li"
                  className="text-muteText radio-label "
                  control={<Radio color="default" />}
                  label="Anonymous"
                />
                <FormControlLabel
                  value="minihub"
                  className="text-muteText radio-label"
                  control={<Radio color="default" />}
                  label="Admin"
                />
              </span>
            </RadioGroup>
          </div>

          <div>
            <TextField
              name="title"
              value={formData?.title || ""}
              onChange={(e) => onChangeHandler(e)}
              id="title"
              label="Title"
              className="w-full"
            />
          </div>

          <div>
            <TextField
              name="description"
              value={formData?.description || ""}
              onChange={(e) => onChangeHandler(e)}
              id="description"
              label="Description"
              className="w-full"
            />
          </div>

          <div>
            <h1>Image</h1>
            <input type="file" accept="image/*" onChange={handleFileChange} />

            {isuploaded && (
              <Cropper
                src=""
                style={{ height: 400, width: "100%" }}
                initialAspectRatio={2 / 1}
                guides={false}
                viewMode={1}
                cropBoxResizable={false}
                crop={onCrop}
                ref={cropperRef}
              />
            )}
          </div>

          {tab === 2 && (
            <div className="relative">
              {numberofpoll?.map((item, index) => (
                <div key={item.id}>
                  {index > 0 && <hr className="mt-4"></hr>}
                  <div
                    className="absolute right-0 bottom-0 cursor-pointer z-[10]"
                    onClick={() => {
                      sernumberofpoll((prevArray) => [...prevArray, item + 1]);
                    }}
                  >
                    {" "}
                    <div className="plusIconImg  wh_40p bottom_24px_imp cursor-pointer">
                      ➕
                    </div>
                  </div>
                  <TextField
                    name={`Poll`}
                    value={polldetails[index]?.Poll || ""}
                    onChange={(e) => {
                      const newValues = [...polldetails];
                      newValues[index] = {
                        ...newValues[index],
                        Poll: e.target.value,
                      };
                      setpolldetials(newValues);
                    }}
                    id={`Poll`}
                    label={`Poll ${index + 1}`}
                    className="w-full"
                  />
                </div>
              ))}
            </div>
          )}
        </div>

        <div className="flex justify-center mt-10">
          <button className="button-1 mx-auto" disabled={loader ? true : false}>
            Add {tab === 1 ? "Post" : "Poll"}
          </button>
        </div>
      </form>
    </Fragment>
  );
};

export default GroupPostAndPollComponent;
