import Form from "./Form";
import { useContext, useState } from "react";
import { RadioGroup, FormControlLabel, Radio } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { postMethodFunction } from "../../../Utils/services";
import { SPECIALITY_ROUTES } from "../../../Utils/routes";
import { toast } from "react-toastify";
import { ModalContext } from "../../../Context";
import SmallSpinner from "../../Common/SmallSpinner";

const AffiliationForm = ({ title, setAffiliationData }) => {
  const [loader, setLoader] = useState(false);
  const [formType, setformType] = useState("party");
  const [allFieldsData, setAllFieldsData] = useState({});
  const context = useContext(ModalContext);
  const { closeModal } = context;

  const formSubmit = async (e) => {
    setLoader(true);
    e.preventDefault();
    // const data = {
    //   speciality: allFieldsData?.sepeciality,
    //   description: allFieldsData?.description,
    // };
    const data = {
      speciality: allFieldsData?.sepeciality,
      description: allFieldsData?.description,
    };
    try {
      const result = await postMethodFunction({
        URLPATH: SPECIALITY_ROUTES,
        DATA: data,
        TYPE: "body",
      }); //
      // {"speciality":"Repair ","description":"Repair description"}
      setAffiliationData();

      setAllFieldsData({});
      setLoader(false);
      closeModal();
    } catch (e) {
      setLoader(false);
      toast.error(e.message);
    }
  };

  const onInputChange = (event) => {
    const _name = event.target.name;
    const _value = event.target.value;

    setAllFieldsData({
      ...allFieldsData,
      [_name]: _value,
    });
  };

  return (
    <>
      <div className="flex items-center justify-center">
        <h3 className="title"> {title} </h3>
      </div>
      <form onSubmit={formSubmit} autoComplete="off" className="w-full">
        <div className="grid grid-cols-2 gap-x-24 gap-y-4">
          <TextField
            required
            label="Speciality"
            name="sepeciality"
            onChange={onInputChange}
            value={allFieldsData.sepeciality || ""}
          />

          <TextField
            name="description"
            label="Enter Description"
            aria-label="minimum height"
            onChange={onInputChange}
            minRows={3}
          />
        </div>

        <div className="flex justify-center mt-10">
          <button className="button-1 flex gap-2 mx-auto" disabled={loader ? true : false}>
            <SmallSpinner Loading={loader} /> Add Speciality
          </button>
        </div>
      </form>
    </>
  );
};

export default AffiliationForm;
