import logo from "../../Assets/images/Curbside.png";
// import logo from "../../Assets/images/LoginImage.png";
import { loginServiceFirst } from "../../Utils/services";
import { useState, useContext } from "react";
import { toast } from "react-toastify";
import AuthContext from "../../Utils/auth-context";
import { Link, useNavigate } from "react-router-dom";
import { detectBrowserAndDevice } from "../../functions";

const Login = () => {
  const authCtx = useContext(AuthContext);
  const navigate = useNavigate();
  const [isForgetActive, setForgetActive] = useState(false);
  const [userData, setUserData] = useState({
    email: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);
  const loginSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      if (userData.email && userData.password) {
        const data = {
          email: userData.email,
          password: userData.password,
          deviceInfo: {
            deviceId: detectBrowserAndDevice().deviceId,
            deviceName:
              detectBrowserAndDevice().device +
              " " +
              detectBrowserAndDevice().browser,
          },
        };
        const result = await loginServiceFirst(data);
        if (result.res.data) {
          setLoading(false);
          localStorage.setItem("AuthData", result.res?.data);
          localStorage.setItem("adminId", result.res.data.id);
          localStorage.setItem("refreshToken", result.res.data.refreshToken);
          toast.success(result.res.message || "User Authenticated!");
          authCtx.login(
            result.res.data.accessToken,
            result.res.data.refreshToken,
            true
          );

          navigate("/users");
        }
      }
    } catch (error) {
      toast.error(error.message);
      setLoading(false);
    }
  };
  // New
  const handleChange = (e) => {
    setUserData({
      ...userData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    loginSubmit(e);
  };

  return (
    <div className="w-2/5 mx-auto auth-container">
      <div className="w-1/2 mx-auto text-center">
        <img src={logo} className="mx-auto mb-5 w-full" alt="logo" />

        {/* <h2 className="primary-color font-sans text-2xl	 font-bold mb-10">
          {isForgetActive ? "FORGET PASSWORD" : "SIGN IN"}
        </h2> */}

        <form
          className="auth-form"
          onSubmit={(e) => handleSubmit(e)}
          style={{
            marginTop: "0.5rem",
          }}
        >
          <input
            type="email"
            placeholder="Email ID"
            name="email"
            value={userData.email}
            onChange={(e) => handleChange(e)}
            required
          />
          <input
            type="password"
            placeholder="Password"
            name="password"
            value={userData.password}
            onChange={(e) => handleChange(e)}
            required
          />

          {loading ? (
            <p>Loading...</p>
          ) : (
            <button className="primary-btn" type="submit">
              {isForgetActive ? "submit" : "sign in"}
            </button>
          )}
          <span className="text-btn" onClick={() => setForgetActive(true)}>
            <Link to="/forgotpassword">Forgot Password ?</Link>
          </span>
        </form>
      </div>
    </div>
  );
};

export default Login;
