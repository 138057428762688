import React, { Fragment, useState } from "react";
import { PollPercentageCalculation, filterPostType } from "../../functions";
import Pagination from "@material-ui/lab/Pagination";
import { Tooltip } from "@material-ui/core";
import { getMethodFunction } from "../../Utils/services";
import { BASE_URL_IMG } from "../../Utils/services";
import { POST_COMMENT_API } from "../../Utils/routes";
import { toast } from "react-toastify";
import GroupPreviewModalComponentt from "./GroupPreviewModal";
import { CURBSIDE_SVG, NameSvgICon } from "../../Assets/SVGs/Svgcomponent";

const GroupPreviewDataCOmponent = ({
  data,
  groupName,
  Paginationdata,
  handlePagination,
}) => {
  const [commentdetails, setcommentdetails] = useState();
  const [resultdata, setresultdata] = useState();
  const [perticualarpostdata, setperticularpostdata] = useState();
  const [openModal, setopenModal] = useState(false);

  console.log(commentdetails);

  const commentHandler = async (data) => {
    if (commentdetails === data?._id) {
      setcommentdetails("");
      return;
    }
    try {
      const result = await getMethodFunction({
        UrlPath: `${data?._id}/comment?pageno=1&pagesize=5`,
      });
      setperticularpostdata(data);
      console.log(result);
      setopenModal(true);
      setresultdata(result?.res);
      setcommentdetails(data?._id);
    } catch (err) {
      console.error(err);
      toast.error("Sorry, We are unavailable at the moment");
    }
  };
  return (
    <Fragment>
      <div className="p-[20px] grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-4 gap-4">
        {data &&
          data?.length > 0 &&
          data?.map((item) => {
            return (
              <React.Fragment key={item.id}>
                {item.posttype === 1 && (
                  <div className="max-w-md w-full p-6 bg-white rounded-lg shadow-md mt-[0.5rem]">
                    <div className="flex gap-2">
                      <div className="smallHEaderdesign">
                        {groupName?.value?.group_name?.charAt(0)}
                      </div>
                      <div>
                        <h2 className="text-lg font-semibold mb-4">
                          {groupName?.value?.group_name}
                        </h2>
                      </div>
                    </div>
                    <h2 className="text-md font-light mb-4">{item.title}</h2>
                    <ul>
                      {item.options.map((poldata) => (
                        <li className="polling" key={poldata.id}>
                          <span
                            className="progress-bar"
                            style={{
                              width: `${PollPercentageCalculation(
                                item.options,
                                poldata.count
                              )}%`,
                            }}
                          />
                          <span className="progress-text">
                            {`${PollPercentageCalculation(
                              item.options,
                              poldata.count
                            )}%`}
                          </span>
                          <span>{poldata.label}</span>
                        </li>
                      ))}
                    </ul>
                    <div className="flex justify-between items-center border-t mt-5 border-gray-300 pt-4">
                      <span className="text-gray-500">
                        Votes:{" "}
                        <span id={`vote-count-${item.id}`}>
                          {item.options[0].count + item.options[1].count}
                        </span>
                      </span>
                      <button
                        className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
                        // onClick={() => handleVote(item.id)}
                      >
                        Vote
                      </button>
                    </div>
                  </div>
                )}
                {item.posttype === 0 && (
                  <div
                    className={`w-full   flex justify-between bg-white rounded-lg shadow-md p-[12px] rounded-[12px]  ${
                      commentdetails === item._id &&
                      1 === 2 &&
                      "col-span-2 transition-all	"
                    }`}
                  >
                    <div className="max-w-sm rounded overflow-hidden  mx-auto">
                      <div className="flex gap-2 items-center">
                        {item?.postedastype === 0 ? (
                          <img
                            src={`${BASE_URL_IMG}/uploads/profile/${item?._id}/${item?._id}.jpeg`}
                            className="h-[2rem] min-h-[2rem] min-w-[2rem]"
                            alt=""
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = `data:image/svg+xml;base64,${btoa(`
      <svg xmlns="http://www.w3.org/2000/svg" width="2rem" height="2rem"  viewBox="0 0 100 100">
      <rect width="100%" height="100%" fill="#FCAD73" rx="50%" ry="50%" />
        <text x="50%" y="50%" font-size="40" text-anchor="middle" dominant-baseline="middle" fill="#52575d" font-family="GillSansMedium">
        ${item?.postedas?.charAt(0) ?? ""}${item?.postedas
                                ?.split(" ")?.[1]
                                ?.charAt(0) ?? ""}
        </text>
      </svg>
    `)}`;
                            }}
                          />
                        ) : (
                          <div>{CURBSIDE_SVG?.briefCaseicons}</div>
                        )}

                        <div>
                          <h2 className="headersdesing">{item?.postedas}</h2>
                          <h3 className="subheaderdesign">
                            {groupName?.value?.group_name}
                          </h3>
                        </div>
                      </div>
                      {}
                      <div className="flex justify-between">
                        <div className="textheader ">{item.title}</div>
                      </div>

                      <div className="flex justify-between">
                        <p className="subheaderdesign">{item.description}</p>
                      </div>
                      <img
                        className="w-full rounded-[12px] mt-2"
                        src={
                          !item?.images?.[0]
                            ? "https://img.freepik.com/free-photo/3d-electric-car-building_23-2148972401.jpg?w=1380&t=st=1711538746~exp=1711539346~hmac=55f8a38af3500480092f416e0637207138d332e35bbb8988b2ba970b91100f0e"
                            : `${BASE_URL_IMG}/posts/${item?._id}/${item?.images?.[0]}`
                        }
                        alt="Post Image"
                      />
                      <div className="px-6 py-4">
                        <div className="flex justify-between items-center">
                          <p className="text-gray-700 text-base flex gap-2 items-center">
                            {CURBSIDE_SVG?.likeicons} {item.Likes}
                          </p>
                          <Tooltip
                            title="View Comments"
                            placement="bottom"
                            className="cursor-pointer"
                          >
                            <p
                              className="text-gray-700 cursor-pointer flex hover:text-black cursor-pointer text-base items-center"
                              onClick={() => commentHandler(item)}
                            >
                              {CURBSIDE_SVG?.commentcions}
                            </p>
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                    {commentdetails === item._id && 1 === 2 && (
                      <div className="w-[50%] shadow-lg">
                        <h1 className="comments_headerdesing">Comments</h1>
                      </div>
                    )}
                  </div>
                )}
                {/* {commentdetails === item._id && (
                  <div className="max-w-md w-full p-6 bg-white rounded-lg shadow-md mt-2 mt-2">
                  <div className="max-w-sm rounded overflow-hidden shadow-lg mx-auto">

                    ss
                    </div>
                  </div>
                )} */}
              </React.Fragment>
            );
          })}
      </div>

      <GroupPreviewModalComponentt
        isModalOpen={openModal}
        closeModal={() => setopenModal(false)}
        modalData={resultdata}
        perticulatdata={perticualarpostdata}
        handlePagination={async (event, value) => {
          try {
            const result = await getMethodFunction({
              UrlPath: `${perticualarpostdata?._id}/comment?pageno=${value}&pagesize=5`,
            });

            setresultdata(result?.res);
          } catch (err) {
            console.error(err);
          }
        }}
      />

      <Pagination
        count={Paginationdata?.totalPages}
        variant="outlined"
        shape="rounded"
        className="flex items-center"
        onChange={handlePagination}
        page={Paginationdata?.CurrentPages}
      />
    </Fragment>
  );
};

export default GroupPreviewDataCOmponent;
