import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import classNames from "classnames";
import { Box } from "@material-ui/core";
import { BASE_URL_IMG, getMethodFunction } from "../../Utils/services";
import { Pagination } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end", // Aligning content to the right
    position: "right",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    width: "60%", // Adjust the width as per your requirement
    outline: "none", // Removing outline
    borderRadius: 8, // Adding border radius if needed
    overflow: "hidden", // Hide content overflow
    position: "right",
  },
}));

const style = {
  position: "absolute",
  top: 83,
  right: 0,
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 1,
  outLine: "none",
  heigth: "60vh",
};

const sizes = {
  xl: "max-w-7xl",
  md: "max-w-xl",
  sm: "max-w-lg",
};

const GroupPreviewModalComponentt = ({
  handleModalData,
  isModalOpen,
  modalData,
  modalSize,
  perticulatdata,
  handlePagination,
  closeModal,
}) => {
  const classes = useStyles();
  const [replydetailsdata, setreplydetailsdata] = useState();
  const [viewrepliesdata, setviewrepliesdata] = useState();


  const replydetailshandler = async (data) => {
    // console.log(data);
    try {
      // const result = await getMethodFunction({
      //   UrlPath: `${perticulatdata?._id}/${data?._id}`,
      //   type: "param",
      //   data: {
      //     pagesize: 5,
      //     pageno: 1,
      //   },
      // });
      if (data?._id=== replydetailsdata) {
        setreplydetailsdata("");
        setviewrepliesdata();
      } else {
        setviewrepliesdata(data?.replies);
        setreplydetailsdata(data?._id);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const formatDate = (date) => {
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
      weekday: "long",
    };

    const formattedDate = new Date(date).toLocaleString("en-US", options);
    return formattedDate;
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={isModalOpen}
        onClose={closeModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        {/* <Fade in={isModalOpen}>
          <div
            id="custom-modal"
            className={classNames({
              [classes.paper]: true,
              [sizes[modalSize]]: true,
            })}
          >
            <span className="cross" onClick={closeModal} />
            { modalData }
          </div>
        </Fade> */}
        <Box sx={style}>
          <div className={`header_modal_design  $`}>
            <h3 className="modal_heading">Comments</h3>
            <div onClick={closeModal} className="cursor-pointer">
              X
            </div>
          </div>
          <div className="modal_height_desing">
            <div className="flex items-center mb-4">
              <div className="w-[4rem] h-[4rem] rounded-full overflow-hidden mr-4">
                <img
                  src={
                    !perticulatdata?.images?.[0]
                      ? "https://img.freepik.com/free-photo/3d-electric-car-building_23-2148972401.jpg?w=1380&t=st=1711538746~exp=1711539346~hmac=55f8a38af3500480092f416e0637207138d332e35bbb8988b2ba970b91100f0e"
                      : `${BASE_URL_IMG}/posts/${perticulatdata?._id}/${perticulatdata?.images?.[0]}`
                  }
                  alt="Circle Background"
                  className="object-cover w-full h-full"
                />
              </div>
              <div className="text-xl font-bold">{perticulatdata?.title}</div>
            </div>
            <hr className="mb-2"></hr>

            {modalData?.allComments?.map((item, index) => (
              <article className="p-[1rem] text-base bg-white rounded-lg dark:bg-gray-900 shadow-md mb-[4px]">
                <footer className="flex justify-between items-center mb-2">
                  <div className="flex items-center justify-between">
                    <p className="inline-flex items-center mr-3 text-sm text-gray-900 dark:text-white font-semibold">
                      <div className="smallHEaderdesign mr-2">
                        {item?.UserDetails?.firstname?.charAt(0)}{" "}
                        {item?.UserDetails?.lastname?.charAt(0)}
                      </div>
                      {item?.UserDetails?.firstname}{" "}
                      {item?.UserDetails?.lastname}
                    </p>
                    <p className="text-sm text-gray-600 dark:text-gray-400">
                      <time
                        pubdate="pubdate"
                        dateTime="2022-02-08"
                        title="February 8th, 2022"
                      >
                        {formatDate(item?.createdOn)}
                      </time>
                    </p>
                  </div>
                  <div>
                    <p className="text-sm text-gray-600 dark:text-gray-400">
                      <time
                        pubdate="pubdate"
                        dateTime="2022-02-08"
                        title="February 8th, 2022"
                      >
                        ❤️{item?.Likes}
                      </time>
                    </p>
                  </div>
                </footer>
                <p className="text-gray-500 dark:text-gray-400">
                  {item?.Comment}
                </p>
                {console.log(item)}
                <div
                  className="flex items-center mt-4 space-x-4"
                  onClick={() => replydetailshandler(item)}
                >
                  <button
                    type="button"
                    className="flex items-center text-sm text-gray-500 hover:underline dark:text-gray-400 font-medium"
                  >
                    <svg
                      className="mr-1.5 w-3.5 h-3.5"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 20 18"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M5 5h5M5 8h2m6-3h2m-5 3h6m2-7H2a1 1 0 0 0-1 1v9a1 1 0 0 0 1 1h3v5l5-5h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1Z"
                      />
                    </svg>
                    Reply
                  </button>
                </div>
                {replydetailsdata === item?._id && (
                  <>
                    <hr></hr>
                    {viewrepliesdata && viewrepliesdata?.length > 0 && viewrepliesdata?.map((replyItem, index) => (
                      <article
                        key={index}
                        className="p-[1rem] text-base bg-white rounded-lg dark:bg-gray-900 shadow-sm"
                      >
                        <footer className="flex justify-between items-center mb-2">
                          <div className="flex items-center justify-between">
                            <p className="inline-flex items-center mr-3 text-sm text-gray-900 dark:text-white font-semibold">
                              <div className="smallHEaderdesign mr-2">
                                {replyItem?.UserDetails?.firstname?.charAt(0)}{" "}
                                {replyItem?.UserDetails?.lastname?.charAt(0)}
                              </div>
                              {replyItem?.UserDetails?.firstname}{" "}
                              {replyItem?.UserDetails?.lastname}
                            </p>
                            <p className="text-sm text-gray-600 dark:text-gray-400">
                              <time dateTime={replyItem?.createdOn}>
                                {formatDate(replyItem?.createdOn)}
                              </time>
                            </p>
                          </div>
                          <div>
                            {/* <p className="text-sm text-gray-600 dark:text-gray-400">
                              <time dateTime={replyItem?.createdOn}>
                                ❤️{replyItem?.Likes}
                              </time>
                            </p> */}
                          </div>
                        </footer>
                        <p className="text-gray-500 dark:text-gray-400">
                          {replyItem?.Reply}
                        </p>
                      </article>
                    ))}
                  </>
                )}
              </article>
            ))}
          </div>
          <div className={`flex flex-end justify-end modal_bottom_desing`}>
            <div className="ml-3">
              <Pagination
                count={modalData?.pagination?.totalPages}
                variant="outlined"
                shape="rounded"
                className="flex items-center"
                onChange={handlePagination}
                page={1}
              />
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default GroupPreviewModalComponentt;
