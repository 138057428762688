import React from 'react';
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import App from './Components/App';
import './Assets/styles.css'
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import theme from "./Material_UI_Theme"
import { AuthContextProvider } from './Utils/auth-context'
import NetworkServices from './Utils/network-services'

const root = ReactDOM.createRoot(document.getElementById("root"));

NetworkServices();
root.render(
  // <React.StrictMode>
    <AuthContextProvider>
      <Router>
        {/* <ThemeProvider theme={theme}> */}
          <App />
        {/* </ThemeProvider> */}
      </Router>
    </AuthContextProvider>
  // </React.StrictMode>,
);

