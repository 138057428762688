import icon1 from "../../Assets/images/icons/people.svg"
import icon2 from "../../Assets/images/icons/feeds.svg"
import icon3 from "../../Assets/images/icons/flag.svg"
import icon4 from "../../Assets/images/icons/master.svg"
import icon5 from "../../Assets/images/icons/calender.svg"
import icon6 from "../../Assets/images/icons/notification.svg"



export const navlist = [
    {
        label: "People",
        path: "/users",
        ref: "people",
        icon: icon1
    },

    {
        label: "Groups",
        path: "/group",
        ref: "group",
        icon: icon2
    },

    {
        label: "Reported",
        path: "/reported",
        ref: "reported",
        icon: icon3
    },

    {
        label: "Master",
        path: "/master",
        ref: "master",
        icon: icon4
    },

    {
        label: "Events",
        path: "/events",
        ref: "events",
        icon: icon5
    },
    {
        label: "NOTIFICATION",
        path: "/notification",
        ref: "notification",
        icon: icon6
    },
]