import React, { useState } from "react";

const AuthContext = React.createContext({
  isSuperAdmin: false,
  token: "",
  refreshToken: "",
  isLoggedIn: true,
  login: (token, refreshToken) => {},
  logout: () => {},
});

export const AuthContextProvider = (props) => {
  const initialToken = localStorage.getItem("token");
  const initialRefreshToken = localStorage.getItem("refreshToken");
  const [token, setToken] = useState(initialToken);
  const [refreshToken, setRefreshToken] = useState(initialRefreshToken);
  const userIsLoggedIn = !!token;
  const [isSuperAdmin, setIsSuperAdmin] = useState(
    localStorage.getItem("isSuperAdmin") || false
  );

  const loginHandler = (token, refreshToken, isSuperAdmin) => {
    setToken(token);
    setRefreshToken(refreshToken);
    localStorage.setItem("token", token);
    localStorage.setItem("refreshToken", refreshToken);
    localStorage.setItem("isSuperAdmin", isSuperAdmin);
    setIsSuperAdmin(isSuperAdmin);
  };
  const logoutHandler = () => {
    setToken(null);
    setRefreshToken(null);
    localStorage.removeItem("token");
    localStorage.removeItem("refreshtoken");
    localStorage.removeItem("isSuperAdmin");
    setIsSuperAdmin(false);
  };

  const contextValue = {
    token: token,
    refreshToken: refreshToken,
    isLoggedIn: userIsLoggedIn,
    isSuperAdmin: isSuperAdmin,
    login: loginHandler,
    logout: logoutHandler,
  };
  return (
    <AuthContext.Provider value={contextValue}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
