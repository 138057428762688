import React, { useMemo } from "react";
import { useTable } from 'react-table';

const UsersTable = ({ columns, data }) => {

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data });

  return (
    <table
      className="w-full mt-5 table-theme-1"
      {...getTableProps()}
      cellSpacing={5}
    >
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column, idx) => (
              <th key={idx} {...column.getHeaderProps()}>
                {column.render('Header')}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, rdx) => {
          prepareRow(row);
          return (
            <tr key={rdx} {...row.getRowProps()}>
              {row.cells.map((cell, cdx) => {
                return (
                  <td key={cdx} {...cell.getCellProps()}>
                    {cell.render('Cell')}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default UsersTable;