import React, { useEffect, useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

const ReactCalendarPage = ({
  eventAllData,
  showEvent,
  value,
  onChange,
  activeStartDateChange,
}) => {
  const formatDate = (date) => {
    const _date = new Date(date);
    const month = _date.getMonth() + 1;
    const year = _date.getFullYear();
    const newdate = _date.getDate();
    return `${newdate}/${month}/${year}`;
  };

  const handleTileClassName = ({ date }) => {
    const _newData = [];
    eventAllData &&
      eventAllData.map((item) => {
        _newData.push(item.current);
      });
    const _res = _newData.filter((item) => {
      return formatDate(item) === formatDate(date);
    });

    for (let x in _newData) {
      if (formatDate(_res[0]).includes(formatDate(_newData[x]))) {
        return "cutome_calander_class";
      }
    }
  };

  console.log(eventAllData);
  const [selectedDate, setSelectedDate] = useState(new Date());

  // Mock data - replace this with your actual data
  const eventData = {
    "2024-03-01": "Event 1",
    "2024-03-05": "Event 2",
    "2024-03-15": "Event 3",
  };

  // Function to render calendar tile content
  const tileContent = ({ date }) => {
    const eventsOnDate = eventAllData.filter(event => {
      const eventDate = new Date(event.startdate);
      return (
        eventDate.getDate() === date.getDate() &&
        eventDate.getMonth() === date.getMonth() &&
        eventDate.getFullYear() === date.getFullYear()
      );
    });

    return eventsOnDate.length > 0 ? eventsOnDate.map(event => <p className="text-[#F3535F]" key={event.title}>{event.title}</p>) : null;
  };
  return (
    <>
      <Calendar
        onChange={onChange}
        events={eventAllData}
        value={value}
        onActiveStartDateChange={activeStartDateChange}
        // className="cutome_calander_class"
        tileContent={tileContent}
        tileClassName={handleTileClassName}
      />

      {/* <Calendar
        onChange={setSelectedDate}
        value={selectedDate}
        tileContent={tileContent}
      /> */}
    </>
  );
};

export default ReactCalendarPage;
