import Table from "../../Common/Table";
import { Button, Grid, Switch, TextField } from "@material-ui/core";
import { useEffect, useState, useContext, useMemo } from "react";
import AntSwitch from "../../Common/AntSwitch";
import { ModalContext } from "../../../Context";
import Form from "../../ModalTemplate/AffiliationForm/Form";
import { getPartyData } from "../getRequests";
import Autocomplete from "@material-ui/lab/Autocomplete";
import MaintenanceModeConfirmation from "../../ModalTemplate/MaintenanceModeConfirmation";
import modalTextsFile from "../../ModalTemplate/modalTextsFile";
import {
  BASE_URL_IMG,
  getMethodFunction,
  partyDisabledApi,
  putMethodFunction,
} from "../../../Utils/services";
import { toast } from "react-toastify";
import { SPECIALITY_ROUTES } from "../../../Utils/routes";

const Affiliation = ({ affiliationData, setAffiliationData }) => {
  const PROMPT_DATA_COLUMN = useMemo(
    () => [
      {
        Header: "Speciality",
        accessor: "speciality",
      },
      {
        Header: "Description",
        accessor: "description",
      },
      {
        Header: "Staus",
        accessor: (data) => {
          return (
            <>
              <Switch checked={true} onChange={() => handleChange(data)} />
            </>
          );
        },
      },
    ],
    []
  );

  const handleChange = async (value) => {
    try {
      const result = await putMethodFunction({
        URLPATH: `${value?._id}/disable`,
      });
      if (result?.res) {
        const result = await getMethodFunction({ UrlPath: SPECIALITY_ROUTES });

        setAffiliationData(result?.res);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const result = await getMethodFunction({ UrlPath: SPECIALITY_ROUTES });
        console.log(result);

        setAffiliationData(result?.res);
      } catch (err) {
        toast.error(err.message);
      }
    })();
  }, []);

  return (
    <>
      {affiliationData && (
        <Table columns={PROMPT_DATA_COLUMN} data={affiliationData || []} />
      )}
    </>
  );
};

export default Affiliation;
