import { useEffect, useContext, useState } from "react";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import AntSwitch from "../Common/AntSwitch";
import {
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
} from "@material-ui/core";
// import TextareaAutosize from '@mui/material/TextareaAutosize';
import Autocomplete from "@material-ui/lab/Autocomplete";
import { ModalContext } from "../../Context";
import { toast } from "react-toastify";
import Loader from "../Common/Loader";
import SmallSpinner from "../Common/SmallSpinner";

const _helperText = {
  badge:
    "*start typing, the list will show up for multiple selects - Press enter to make an entry and then again start typing for selecting the second option",
  expertise:
    "*start typing, the list will show up for multiple selects - Press enter to make an entry and then again start typing for selecting the second option",
};

const HubsForm = ({
  title,
  btnText,
  submitHandler,
  getMembers,
  id,
  refreshDataMethod,
  setData,
}) => {
  const [loader, setLoader] = useState(false);
  const [allFieldsData, setAllFieldsData] = useState({});

  const context = useContext(ModalContext);
  const { closeModal } = context;

  const onInputChange = (event, newInputValue, name) => {
    const _name = event.target.name;
    const _value = event.target.value;

    if (name == "members") {
      setAllFieldsData({
        ...allFieldsData,
        [name]: newInputValue,
      });
    } else {
      setAllFieldsData({
        ...allFieldsData,
        [_name]: _value,
      });
    }
  };

  const formSubmit = async (e) => {
    setLoader(true);
    e.preventDefault();
    const data = {
      designation: allFieldsData?.designation,
      description: allFieldsData?.description,
    };

    try {
      const result = await submitHandler(data);
      //

      setAllFieldsData({});
      setLoader(false);
      closeModal();
    } catch (e) {
      setLoader(false);
      toast.error(e.message);
    }
  };

  return (
    <>
      <div className="flex items-center justify-center">
        <h3 className="title"> {title} </h3>
      </div>

      <form onSubmit={formSubmit} autoComplete="off" className="w-full">
        <div className="grid grid-cols-2 gap-x-24 gap-y-4">
          <TextField
            required
            label="Designation"
            name="designation"
            onChange={onInputChange}
            value={allFieldsData.designation || ""}
          />

          <TextField
            name="description"
            label="Enter Description"
            aria-label="minimum height"
            onChange={onInputChange}
            minRows={3}
          />
        </div>

        <div className="flex justify-center mt-10">
          <button className="button-1 mx-auto flex gap-2" disabled={loader ? true : false}>
            <SmallSpinner Loading={loader} /> {btnText}
          </button>
        </div>
      </form>
    </>
  );
};

export default HubsForm;
