import React, { useState, useContext, useEffect } from "react";
import UsersTable from "./UsersTable";
import { useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";
import { useNavigate, useMatch, useLocation } from "react-router-dom";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import {
  BASE_URL_IMG,
  InviteAllPeopleNotification,
  InviteNotification,
} from "../../Utils/services";
import { Button } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      marginTop: theme.spacing(2),
    },
  },
}));

const Admin = ({ data, getAdminTableData }) => {
  const [searchValue, setSearchValue] = useState("");

  let { pathname } = useLocation();
  const navigate = useNavigate();
  const param = useParams();
  //console.log(param, "Param")


  // useEffect(() => {
  //   getAdminTableData();
  // }, []);

  const showPostDetail = (item) => {

    navigate(`${pathname}/${item._id}`, {
      state: item._id,
    });
  };

  const handleInvite = async (data) => {
    //console.log(data.row.original._id)
    try {
      const result = await InviteNotification({
        people: "admin",
        id: data.row.original._id,
      });
      if (result.res.success) {
        toast.success(result.res.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };
  const handleInviteAll = async () => {
    try {
      const result = await InviteAllPeopleNotification({
        people: "admin",
      });
      if (result.res.success) {
        toast.success(result.res.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const adminColumns = useMemo(
    () => [
      {
        Header: "FirstName",
        accessor: "firstname",
      },
      {
        Header: "LastName",
        accessor: "lastname",
      },
      {
        Header: "Mobile",
        accessor: "mobile",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "D.0.B",
        accessor: "dob",
      },
    ],
    []
  );

  return (
    <>
      <div className="flex justify-between items-center">
        {/* <div>
              <Pagination count={10} variant="outlined" shape="rounded" />
            </div> */}
        <div className="bg-white p-2 flex items-center search-wrapper">
          <span>
            <img src="/assets/icons/search.svg" />
          </span>
          <input
            type="text"
            value={searchValue}
            placeholder="Search"
            className="w-96 mx-3"
            onChange={(e) => setSearchValue(e.target.value)}
          />
          <span>
            <img src="/assets/icons/submit-arrow.svg" />
          </span>
        </div>
        <Button className="btn2" onClick={handleInviteAll} disabled>
          {" "}
          <span className="px-2">Invite All</span>{" "}
        </Button>
      </div>
      {data && <UsersTable columns={adminColumns} data={data || []} />}
    </>
  );
};

export default Admin;
