import { useState, useContext } from 'react';
import TextField from '@material-ui/core/TextField';
import { addExpertise, editExpertise, deleteExpertise } from '../../Utils/services';
import { toast } from 'react-toastify';
import { ModalContext } from '../../Context'
import { Button } from "@material-ui/core";
import modalTextsFile from "./modalTextsFile"
import RemoveConfirmation from "./RemoveConfirmation"
import { getExpertiseData } from '../Master/getRequests';
import Loader from "../Common/Loader"

const AddExpertise = ({
    setExpertiseData,
    title,
    edit,
    btnText,
    area,
    id
}) => {
    const [expertise, setExpertise] = useState(area || "");
    const [loader, setLoader] = useState(false);
    const modalContext = useContext(ModalContext);
    const { closeModal, handleModalData } = modalContext;


    const delRequest = async (id) => {
        setLoader(true)
        let data = {
            "id": id
        }
        try {
            const result = await deleteExpertise(data);

            if (result.res.success) {
                const _data = await getExpertiseData();
                setExpertiseData(_data);
                toast.success("Expertise Deleted!");
                setLoader(false)
                closeModal();
            }
        }
        catch (e) {
            setLoader(false)
            //console.log(e);
            toast.error(e.message);
        }
    }

    const REMOVE_CONFIRMATION = <RemoveConfirmation
        title="REMOVE FEED"
        action={delRequest}
        id={id}
        data={modalTextsFile.expertiseRemoveText}
    />

    const handleAdd = async (e) => {
        setLoader(true)
        e.preventDefault()
        let data = {
            "area": expertise
        }
        try {
            const result = await addExpertise(data);

            if (result.res.success) {
                const _data = await getExpertiseData();
                setExpertiseData(_data);
                toast.success(result.res.message);
                setLoader(false)
                closeModal();
            }
        }
        catch (e) {
            setLoader(false)
            //console.log(e);
            toast.error(e.messages ?? e.message);
        }
    }

    const handleEdit = async (e) => {
        setLoader(true)
        let data = {
            "area": expertise,
            "id": id
        }
        try {
            const result = await editExpertise(data);

            if (result.res.success) {
                const _data = await getExpertiseData();
                setExpertiseData(_data);
                toast.success(result.res.message);
                setLoader(false)
                closeModal();
            }
        }
        catch (e) {
            setLoader(false)
            //console.log(e);
            toast.error(e.messages ?? e.message);
        }
    }



    return (
        <>
        <Loader loader={loader} />
            <div className="flex items-center justify-center">
                <h3 className="title"> {title} </h3>
            </div>

            <form noValidate autoComplete="off" className="w-full">
                <div className="flex flex-col w-full">
                    <div className="mb-4">
                        <TextField
                            id="name"
                            label="Edit Expertise"
                            className="w-full"
                            value={expertise}
                            onChange={(e) => setExpertise(e.target.value)}
                        />
                    </div>
                </div>


                <div className="flex justify-center mt-10">
                    <button
                        className="button-1 mx-auto"
                        type="button"
                        // onClick={clickHandler}
                        onClick={edit ? handleEdit : handleAdd}
                        disabled ={loader ? true : false}
                    >
                        {btnText}
                    </button>
                </div>

                {
                    edit && (
                        <div className="flex justify-center mt-5">
                            <Button
                                variant="outlined"
                                color="secondary"
                                className="approve_decline_padding"
                                onClick={() => handleModalData(REMOVE_CONFIRMATION, 'sm')}
                                disabled ={loader ? true : false}
                            >
                                <img src="/assets/icons/falseicon.svg" alt="" />{" "}
                                <span className="pl-1">Remove</span>
                            </Button>
                        </div>
                    )
                }

            </form>
        </>
    )
}

export default AddExpertise;