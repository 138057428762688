// import authBg from "../../Assets/images/login-bg.png"
import authBg from "../../Assets/images/banner_new.jpg";
import Login from "./Login";

const Auth = () => {
  return (
    <div className="w-full relative">
      {/* <img src={authBg} alt="" /> */}
      <div
        style={{
          background: "gray",
          height: "40vh",
        }}
      ></div>
      <div className="flex absolute top-1/2 left-0 w-full">
        <Login />
      </div>
    </div>
  );
};

export default Auth;
