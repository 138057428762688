import { useState, useContext } from "react";
import TextField from "@material-ui/core/TextField";
import { ModalContext } from "../../Context";
import {
  addRegion,
  BASE_URL_IMG,
  deleteRegion,
  editRegion,
  postMethodFunction,
} from "../../Utils/services";
import { toast } from "react-toastify";
import { getRegionData } from "../Master/getRequests";
import RemoveConfirmation from "./RemoveConfirmation";
import modalTextsFile from "./modalTextsFile";
import { Button } from "@material-ui/core";
import Loader from "../Common/Loader";
import { ADD_EXPERIENCE } from "../../Utils/routes";
import SmallSpinner from "../Common/SmallSpinner";

const AddRegion = ({
  title,
  setRegionData,
  btnText,
  edit,
  id,
  name,
  image,
}) => {
  const [loader, setLoader] = useState(false);

  const [data, setData] = useState({
    flag: image || "",
    region: name || "",
  });

  const context = useContext(ModalContext);
  const { closeModal, handleModalData } = context;

  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setData({ ...data, [name]: value });
  };

  // THis is example
  const AddExperienceHandler = async (e) => {
    e.preventDefault();
    if (!data.experiencetitle) {
      toast.error("Please Enter Experience Title");
      return;
    }
    setLoader(true);

    const apidata = {
      experiencetitle: data.experiencetitle,
    };
    try {
      const results = await postMethodFunction({
        URLPATH: ADD_EXPERIENCE,
        DATA: apidata,
        TYPE: "body",
      });
      if (results.res) {
        toast.success(results.res.message);
        const _data = await getRegionData();
        setRegionData(_data);
        setLoader(false);
        closeModal();
      }
    } catch (e) {
      setLoader(false);
      //console.log(e);
      toast.error(e.messages ?? e.message);
    }
  };

  return (
    <>
      <div className="flex items-center justify-center">
        <h3 className="title"> {title} </h3>
      </div>

      <form
        onSubmit={AddExperienceHandler}
        autoComplete="off"
        className="w-full"
      >
        <div className="flex flex-col w-full">
          <div className="mb-4">
            <TextField
              required
              id="name"
              label="Experience Title"
              className="w-full"
              name="experiencetitle"
              value={data.experiencetitle}
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>

        <div className="flex justify-center mt-10">
        
            <button
              className="button-1 mx-auto flex gap-2"
              disabled={loader ? true : false}
            >
              <SmallSpinner Loading={loader} /> {btnText}
            </button>
          
        </div>
      </form>
    </>
  );
};

export default AddRegion;
