import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
  },
}));


const sizes = {
  xl: 'max-w-7xl',
  md: 'max-w-xl',
  sm: 'max-w-lg'
}


const CommonModal = ({
  handleModalData,
  isModalOpen,
  modalData,
  modalSize,
  closeModal
}) => {
  const classes = useStyles();
  

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={isModalOpen}
        onClose={closeModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        
        <Fade in={isModalOpen}>
          <div
            id="custom-modal"
            className={classNames({
              [classes.paper]: true,
              [sizes[modalSize]]: true,
            })}
          >
            <span className="cross" onClick={closeModal} />
            { modalData }
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

export default CommonModal;