import { useState, Fragment, useEffect, useMemo } from "react";
import { getMethodFunction } from "../../../Utils/services";
import Table from "../../Common/Table";
import { toast } from "react-toastify";
import { GET_EXPERIENCE } from "../../../Utils/routes";

const ExperienceBodyComponent = ({ regionData, setRegionData }) => {
  const [experiencedata, setexperiencedata] = useState();

  const experiencecolumn = useMemo(
    () => [
      {
        Header: "Experience Title",
        accessor: "experiencetitle",
      },
    ],
    []
  );

  useEffect(() => {
    
    (async () => {
      try {
        const result = await getMethodFunction({ UrlPath: GET_EXPERIENCE });

        setexperiencedata(result?.res);
      } catch (err) {
        toast.error(err.message);
      }
    })();
  }, []);
  return (
    <Fragment>
      {" "}
      <Table columns={experiencecolumn} data={experiencedata || []} />
    </Fragment>
  );
};

export default ExperienceBodyComponent;
