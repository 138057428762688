import logo from "../../Assets/images/logo.svg";
import {
  loginServiceFirst,
  otpVerificationApi,
  otpVerificationLoginApi,
} from "../../Utils/services";
import { useState, useEffect, useContext } from "react";
import { toast } from "react-toastify";
import { useLocation } from "react-router";

import { Link, useNavigate } from "react-router-dom";
import authBg from "../../Assets/images/banner_new.jpg";
import OtpInput from "react-otp-input";
import AuthContext from "../../Utils/auth-context";

const LoginOtpVerification = () => {
  const [otp, setOtp] = useState();
  const location = useLocation();
  const [emailValue, setEmailValue] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);

  useEffect(() => {
    if (location.state !== undefined || location?.state !==null) {
      const { fcmToken, email } = (location?.state || {}) || {};
      setEmailValue(email);
      setVerificationCode(fcmToken);
      //console.log(fcmToken, email, "fcmToken, email,")
    }
    if (location.state === undefined) {
      navigate("/login");
    }
  }, []);

  const handleOtpChange = (e) => {
    //console.log(e)
    setOtp(e);
  };
  const handleOtpSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    try {
      const result = await otpVerificationLoginApi({
        email: emailValue,
        fcmToken: verificationCode,
        otp: parseInt(otp),
      });
      if (result.res.success) {
        // //console.log(result ,"### loginresponse")
        localStorage.setItem("adminId", result.res.data.id);
        localStorage.setItem("refreshToken", result.res.data.refreshToken);
        toast.success(result.res.message || "User Authenticated!");
        authCtx.login(
          result.res.data.accessToken,
          result.res.data.refreshToken,
          result.res.data.isSuperAdmin
        );
        setLoading(false);
        // toast.success(result.res.message)
        navigate("/");
      }
    } catch (error) {
      setLoading(false);
      toast.error(error.message);
    }
  };

  return (
    <div className="w-full relative">
      <img src={authBg} alt="" />
      <div className="flex absolute top-1/2 left-0 w-full">
        <div className="w-2/5 mx-auto auth-container">
          <div className="w-1/2 mx-auto text-center">
            <img src={logo} className="w-1/2 mx-auto mb-10" alt="logo" />
            <h2 className="primary-color font-sans text-2xl	 font-bold mb-10">
              VERIFY OTP
            </h2>
            <form
              className="flex flex-col items-center"
              onSubmit={handleOtpSubmit}
            >
              <div className="mt-2 flex otpSection justify-center items-center">
                <OtpInput
                  value={otp}
                  onChange={handleOtpChange}
                  numInputs={4}
                  // isInputNum={true}
                  renderInput={(props) => <input {...props} />}

                />
              </div>
              {loading ? (
                <p>Loading...</p>
              ) : (
                <button className="primary-btn mt-10" type="submit">
                  Verify OTP
                </button>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginOtpVerification;
