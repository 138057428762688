import React, { Fragment, useEffect, useMemo, useState } from "react";
import { getMethodFunction } from "../../Utils/services";
import { GET_DESIGNATION } from "../../Utils/routes";
import { toast } from "react-toastify";
import Table from "../Common/Table";
import { Switch } from "@material-ui/core";

const DesignationRoutinganddata = ({ designationdata, setdesignationdata }) => {
  const handleChange = async (value) => {
    try {
      const result = await getMethodFunction({
        UrlPath: `${value?._id}/disable`,
      });
      if (result?.res) {
        const result = await getMethodFunction({ UrlPath: GET_DESIGNATION });

        setdesignationdata(result?.res);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const DesignationColumn = useMemo(
    () => [
      {
        Header: "Designation",
        accessor: "designation",
      },

      {
        Header: "Description",
        accessor: "description",
      },
      {
        Header: "Staus",
        accessor: (data) => {
          return (
            <>
              <Switch
                checked={data?.isActive}
                onChange={() => handleChange(data)}
              />
            </>
          );
        },
      },
    ],
    []
  );

  useEffect(() => {
    
    (async () => {
      try {
        const result = await getMethodFunction({ UrlPath: GET_DESIGNATION });

        setdesignationdata(result?.res);
      } catch (err) {
        toast.error(err.message);
      }
    })();
  }, []);

  return (
    <Fragment>
      {" "}
      <Table columns={DesignationColumn} data={designationdata || []} />
    </Fragment>
  );
};

export default DesignationRoutinganddata;
