import React, { useState, useContext } from "react";
import { GROUP_FORM, POST_MODAL } from "../ModalTemplate";
import { ModalContext } from "../../Context";
import { TabPanel } from "../Common/TabPanel";
import InnerHeader from "../Common/InnerHeader";

import { getMethodFunction } from "../../Utils/services";
import { toast } from "react-toastify";

import Loader from "../Common/Loader";
import GROUPDATA from "./Allgroup";
import { GET_GROUPS_API } from "../../Utils/routes";

const a11yProps = (index) => {
  return {
    id: `users-tab-${index}`,
    "aria-controls": `users-tabpanel-${index}`,
  };
};

const GROUPSCMP = () => {
  const [tabsValue, setTabsValue] = useState(0);
  const [loader, setLoader] = useState(true);
  const [totalCount, setotalCount] = useState(0);
  const [groupData, setgroupData] = useState();

  const context = useContext(ModalContext);
  const { handleModalData } = context;

  const getgroupData = async (sendData) => {
    try {
      const result = await getMethodFunction({ UrlPath: GET_GROUPS_API });

      setLoader(false);
      setgroupData(result?.res);
    } catch (error) {
      setLoader(false);
      //console.log(error)
      toast.error(error.message);
    }
  };

  const handlerTabs = (event, newValue) => {
    setTabsValue(newValue);
  };

  const viewFeed = () => {
    handleModalData(POST_MODAL);
  };

  const tabsData = [
    {
      label: "Group",
      data: (
        <>
          {loader && (
            <div
              style={{
                marginTop: "20%",
                marginLeft: "50%",
              }}
            >
              <Loader loader={loader} />
            </div>
          )}
          <GROUPDATA
            totalCount={totalCount}
            getgroupData={getgroupData}
            columns={[]}
            data={groupData}
          />
        </>
      ),
    },
  ];

  const handleModalForm = () => {
    switch (tabsValue) {
      case 0:
        handleModalData(GROUP_FORM(true, getgroupData));
        break;

      case 1:
        handleModalData(GROUP_FORM(true, getgroupData));
        break;

      case 2:
        handleModalData(GROUP_FORM(false, getgroupData));
        break;

      default:
        handleModalData(GROUP_FORM(true, getgroupData));
        break;
    }
  };
  return (
    <div className="relative">
      <InnerHeader
        tabsData={tabsData}
        handleModalForm={handleModalForm}
        tabsValue={tabsValue}
        handlerTabs={handlerTabs}
        a11yProps={a11yProps}
        formBtn
      />
      {tabsData.map((obj, index) => (
        <TabPanel className="px-10" value={tabsValue} index={index} key={index}>
          {obj.data}
        </TabPanel>
      ))}
    </div>
  );
};
export default GROUPSCMP;
