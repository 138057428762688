// import { AppBar, Tab, Tabs, Tooltip } from "@material-ui/core";
// import React, { Fragment, useEffect, useMemo, useState } from "react";
// import classnames from "classnames";
// import { getMethodFunction } from "../../Utils/services";
// import { GET_GROUP_POST_API } from "../../Utils/routes";
// import Table from "../Common/Table";
// import { toast } from "react-toastify";
// import { useLocation } from "react-router-dom";
// import { PollPercentageCalculation, filterPostType } from "../../functions";

// const GroupPreviewCmp = () => {
//   const [grouppostdata, setgrouppostdata] = useState();
//   const location = useLocation();

//   const GROUPS_DATA_COLUME = useMemo(
//     () => [
//       {
//         Header: "Title",
//         accessor: "speciality",
//       },
//       {
//         Header: "Description",
//         accessor: "description",
//       },
//     ],
//     []
//   );

//   console.log(grouppostdata);
//   useEffect(() => {
//     (async () => {
//       try {
//         const result = await getMethodFunction({
//           UrlPath: `${GET_GROUP_POST_API}/${location?.state?.value?._id}`,
//         });

//         setgrouppostdata(result?.res?.posts);
//       } catch (err) {
//         toast.error(err.message);
//       }
//     })();
//   }, []);
//   return (
//     <Fragment>
//       <AppBar position="static" className="bg-white inner-header">
//         <Tabs
//           //   value={tabsValue}
//           //   onChange={handlerTabs}
//           aria-label="users tabs"
//           className="px-10 bg-white relative"
//         >
//           <Tab
//             key={0}
//             label={"Group Preview"}
//             className={classnames({
//               "custom-tab-btn": true,
//               active: true,
//             })}
//             // {...allyprops(0)}
//           />
//         </Tabs>
//       </AppBar>
//       <div className="p-[40px]">
//         {grouppostdata &&
//           grouppostdata.map((item) => {
//             return item?.posttype === 1 ? (
//               <div>
//                 <div
//                   key={item.id}
//                   className="max-w-md w-full p-6 bg-white rounded-lg shadow-md"
//                 >
//                   <h2 className="text-lg font-semibold mb-4">Group Name</h2>

//                   <h2 className="text-md font-light	 mb-4">{item?.title}</h2>

//                   <ul>
//                     <li className="polling">
//                       <span
//                         className="progress-bar"
//                         style={{
//                           width: `${PollPercentageCalculation(
//                             item?.options?.[0]?.count,
//                             item?.options?.[1]?.count
//                           )}%`,
//                         }}
//                       />
//                       <span className="progress-text">
//                         {" "}
//                         {`${PollPercentageCalculation(
//                           item?.options?.[0]?.count,
//                           item?.options?.[1]?.count
//                         )}%`}{" "}
//                       </span>
//                       <span>{item?.options?.[0]?.label} </span>
//                     </li>
//                     <li className="polling">
//                       <span
//                         className="progress-bar"
//                         style={{ width: `${PollPercentageCalculation(item?.options?.[1]?.count, item?.options?.[0]?.count)}%` }}
//                       />
//                       <span className="progress-text"> {`${PollPercentageCalculation(item?.options?.[0]?.count, item?.options?.[1]?.count)}%`} </span>
//                       <span>{item?.options?.[1]?.label} </span>
//                     </li>
//                   </ul>

//                   <div className="flex justify-between items-center border-t mt-5 border-gray-300 pt-4">
//                     <span className="text-gray-500">
//                       Votes: <span id={`vote-count-${item.id}`}>{item?.options?.[0]?.count  + item?.options?.[1]?.count}</span>
//                     </span>
//                     <button
//                       className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
//                       // onClick={() => handleVote(item.id)}
//                     >
//                       Vote
//                     </button>
//                   </div>
//                 </div>
//               </div>
//             ) : null;
//           })}
//       </div>

//       {/* {grouppostdata && (
//         <div className="p-8">
//           <Table columns={GROUPS_DATA_COLUME} data={grouppostdata || []} />
//         </div>
//       )} */}
//     </Fragment>
//   );
// };

// export default GroupPreviewCmp;

import React, { useState, useContext, useEffect } from "react";
import { GROUP_ADD_POLL, GROUP_FORM, POST_MODAL } from "../ModalTemplate";
import { ModalContext } from "../../Context";
import { TabPanel } from "../Common/TabPanel";
import InnerHeader from "../Common/InnerHeader";

import { getMethodFunction } from "../../Utils/services";
import { toast } from "react-toastify";

import Loader from "../Common/Loader";
import GROUPDATA from "./Allgroup";
import { GET_GROUPS_API } from "../../Utils/routes";
import { useLocation } from "react-router-dom";
import GroupPreviewDataCOmponent from "./GropuPreviewData";
import { GET_GROUP_POST_API } from "../../Utils/routes";

const a11yProps = (index) => {
  return {
    id: `users-tab-${index}`,
    "aria-controls": `users-tabpanel-${index}`,
  };
};

const GroupPreviewCmp = () => {
  const [tabsValue, setTabsValue] = useState(0);
  const [loader, setLoader] = useState(true);
  const [totalCount, setotalCount] = useState({
    totalPages: 1,
    CurrentPages: 1,
  });
  const [groupData, setgroupData] = useState();
  const [grouppostdata, setgrouppostdata] = useState([]);
  const location = useLocation();

  const context = useContext(ModalContext);
  const { handleModalData } = context;

  const getgroupData = async (sendData) => {
    try {
      const result = await getMethodFunction({
        UrlPath: `${GET_GROUP_POST_API}/${location?.state?.value?._id}`,
        type: "param",
        data: {
          pagesize: 10,
          pageno: totalCount?.CurrentPages,
        },
      });

      setLoader(false);
      setotalCount({
        totalPages: result?.res?.pagination?.totalPages,
        CurrentPages: totalCount?.CurrentPages,
      });
      setgrouppostdata(result?.res?.posts);
    } catch (error) {
      setLoader(false);
      //console.log(error)
      toast.error(error.message);
    }
  };

  const handlerTabs = (event, newValue) => {
    setTabsValue(newValue);
  };

  const viewFeed = () => {
    handleModalData(POST_MODAL);
  };

  useEffect(() => {
    (async () => {
      try {
        const result = await getMethodFunction({
          UrlPath: `${GET_GROUP_POST_API}/${location?.state?.value?._id}`,
          type: "param",
          data: {
            pagesize: 10,
            pageno: totalCount?.CurrentPages,
          },
        });

        setotalCount({
          totalPages: result?.res?.pagination?.totalPages,
          CurrentPages: totalCount?.CurrentPages,
        });
        
        setgrouppostdata(result?.res?.posts);
      } catch (err) {
        toast.error(err.message);
      }
    })();
  }, [totalCount?.CurrentPages]);

  const tabsData = [
    {
      label: "Group Preview",
      data: (
        <>
          {/* <Loader loader={loader} /> */}
          <GroupPreviewDataCOmponent
            data={grouppostdata}
            groupName={location?.state}
            Paginationdata={totalCount}
            handlePagination={(event, value) => {
              console.log(value);
              setotalCount((prev) => ({
                ...prev,
                CurrentPages: value,
              }));
            }}
          />
        </>
      ),
    },
  ];

  const handleModalForm = () => {
    switch (tabsValue) {
      case 0:
        handleModalData(GROUP_ADD_POLL(true, getgroupData));
        break;

      case 1:
        handleModalData(GROUP_FORM(true, getgroupData));
        break;

      case 2:
        handleModalData(GROUP_FORM(false, getgroupData));
        break;

      default:
        handleModalData(GROUP_FORM(true, getgroupData));
        break;
    }
  };
  return (
    <div className="relative">
      <InnerHeader
        tabsData={tabsData}
        handleModalForm={handleModalForm}
        tabsValue={tabsValue}
        handlerTabs={handlerTabs}
        a11yProps={a11yProps}
        formBtn
      />
      {tabsData?.map((obj, index) => (
        <TabPanel className="px-10" value={tabsValue} index={index} key={index}>
          {obj.data}
        </TabPanel>
      ))}
    </div>
  );
};
export default GroupPreviewCmp;
