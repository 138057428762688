import { NavLink, useNavigate } from "react-router-dom";
import AuthContext from "../../Utils/auth-context";
import { useContext, useEffect, useState } from "react";
import { Button, Menu, MenuItem } from "@material-ui/core";
import { ModalContext } from "../../Context";
import { RESET_PASSWORD } from "../ModalTemplate";
import AntSwitch from "../Common/AntSwitch";
import { maintenanceModeApi } from "../../Utils/services";
import { toast } from "react-toastify";
import modalTextsFile from "../ModalTemplate/modalTextsFile";

const Header = () => {
  const authCtx = useContext(AuthContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const context = useContext(ModalContext);
  const { handleModalData, closeModal } = context;
  const [isMaintenance, setIsMaintenance] = useState(
    localStorage.getItem("maintenance") === " maintenance on" ? true : false
  );
  const navigate = useNavigate();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    setAnchorEl(null);
    authCtx.logout();
    navigate("/login");
  };

  const handleResetPassword = () => {
    setAnchorEl(null);
    handleModalData(RESET_PASSWORD, "sm");
  };

  const handleMaintenance = async () => {
    try {
      const result = await maintenanceModeApi();
      if (result.res.success) {
        localStorage.setItem("maintenance", result.res.message);
        if (result.res.message === " maintenance on") {
          setIsMaintenance(true);
        } else {
          setIsMaintenance(false);
        }
        toast.success(result.res.message);
        closeModal();
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleToggleChange = async (e) => {
    // setIsMaintenance(e.target.checked);
    const MAINTENANCE_MODE = (
      <div></div>
    );
    handleModalData(MAINTENANCE_MODE, "sm");
    // try {
    //     const result = await maintenanceModeApi();
    //     if (result.res.success) {
    //         localStorage.setItem("maintenance", result.res.message)
    //         if (result.res.message === " maintenance on") {
    //             setIsMaintenance(true)
    //         }
    //         else {
    //             setIsMaintenance(false)
    //         }
    //         toast.success(result.res.message)
    //     }
    // } catch (error) {
    //     toast.error(error.message)
    // }
  };

  return (
    <header className="header">
      {/* <div className="search-wrapper">
                <input type="text" placeholder="Search" className="w-full h-full" />
            </div> */}
      <ul className="ml-auto flex items-center lg:px-16 px-24">
        {/* <li className="px-5 flex items-center justify-center">
          <h3 className="text-paraText hover:text-sidenav uppercase">
            maintenance mode
          </h3>
          <AntSwitch
            checked={isMaintenance}
            name="isMaintenance"
            onChange={handleToggleChange}
            className="ml-5"
          />
        </li> */}
        <li className="px-5">
          <NavLink
            exact
            to="/"
            className="text-paraText hover:text-sidenav uppercase"
            activeclassname="navbar_active"
          >
            Home
          </NavLink>
        </li>
        {/* <li className=" flex items-center">
          <NavLink
            exact
            to="/requests"
            className="text-paraText  hover:text-sidenav uppercase"
            activeclassname="navbar_active"
          >
            Requests
          </NavLink>

          <span className=" flex justify-center items-center bg-yellow-300 ml-2 h-10 w-10 rounded-full text-paraText text-lg">
            3
          </span>
        </li> */}

        <li className="pl-5">
          <div>
            <Button
              className="text-paraText t hover:text-sidenav uppercase"
              activeclassname="navbar_active"
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleClick}
            >
              MORE
            </Button>

            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleResetPassword}>Reset Password</MenuItem>
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
          </div>
        </li>
      </ul>
    </header>
  );
};

export default Header;
