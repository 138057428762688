import React, { useState, useMemo, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import HubDescription from "../Common/HubDescription";
import InnerTable from "../Common/InnerTable";
import { Button } from "@material-ui/core";
import { useLocation } from "react-router-dom";
import LatestPosts from "./LatestPosts";
import SimpleBreadcrumbs from "./SimpleBreadcrumbs";
import AddSection from "../ModalTemplate/AddSection";
import { ModalContext } from "../../Context";
import RemoveConfirmation from "../ModalTemplate/RemoveConfirmation";
import modalTextsFile from "../ModalTemplate/modalTextsFile";
import { getAdminTableData, getSingleSection } from "./getRequests";
import EditSection from "../ModalTemplate/EditSection";

const SectionPage = () => {
  const [sectionData, setSectionData] = useState({});
  const [resourcesName, setResourcesName] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const context = useContext(ModalContext);
  const { handleModalData } = context;

  useEffect(() => {
    fetchSectionDetails();
  }, []);

  const handleHubPush = () => {
    navigate("-1");
  };

  const fetchSectionDetails = async () => {
    const { id, name } = location.state;
    setResourcesName(name);
    const res = await getSingleSection(id);
    // const obj = {
    //     data: res
    // }
    //console.log(res, id, 'SingleMiniHub');
    // const formateDate = formatDate(res.minihub.createdOn)
    setSectionData(res);
  };

  // const EDIT_SECTION = <AddSection
  //   title="EDIT SECTION"
  //   btnText="SAVE"
  //   edit
  // />

  // new hariom
  const EDIT_SECTION = (
    <EditSection
      title="EDIT SECTION"
      btnText="SAVE"
      fetchManagers={getAdminTableData}
      sectionDetails={sectionData.section}
      fetchSectionDetails={fetchSectionDetails}
      edit
    />
  );
  // new hariom

  const REMOVE_SECTION = (
    <RemoveConfirmation
      title="REMOVE RESOURCES"
      data={modalTextsFile.sectionRemoveText}
    />
  );

  const editSection = () => {
    handleModalData(EDIT_SECTION);
  };

  const removeSection = () => {
    handleModalData(REMOVE_SECTION, "sm");
  };

  const goTo = (slug) => {
    navigate("/master/slug1/slug2/slug3");
  };

  return (
    <>
      <div className="flex items-center mb-4 ml-10">
        <span
          onClick={handleHubPush}
          className="text-sm text-minihubPara cursor-pointer"
        >
          Resources
        </span>
        <span className="px-2 text-sm">/</span>
        <span className="text-sidenav capitalize text-sm">{resourcesName}</span>
      </div>
      <div className="grid grid-cols-9 gap-x-40 bg-white py-5">
        {Object.keys(sectionData).length > 0 && (
          <>
            <div className="col-span-3 sticky top-0 pl-10">
              <HubDescription
                title="Section 1"
                editHandler={editSection}
                removeHandler={removeSection}
                data={sectionData.section[0]}
                removeBtn="REMOVE RESOURCES"
                section
              />
            </div>

            <div className="col-span-6 pr-12">
              <LatestPosts
                fetchSectionDetails={fetchSectionDetails}
                data={sectionData.posts}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default SectionPage;
