import { useContext, useEffect, useState } from "react";
import { BASE_URL_IMG, getAdminDetails } from "../../Utils/services";
import twitterImg from "../../Assets/images/icons/twitter.svg"
import facebookImg from "../../Assets/images/icons/facebook.svg"
import instagramImg from "../../Assets/images/icons/instagram.svg"
import youtubeImg from "../../Assets/images/icons/youtube.svg"
import AdminDetailEditForm from "../ModalTemplate/AdminDetailEditForm";
import { ModalContext } from "../../Context";

const ManageAcoount = () => {
    const [adminDetails, setAdminDetails] = useState([]);
    
    const context = useContext(ModalContext)
    const { handleModalData } = context;

    const getAdminDetailsApiData = async () => {
        const id = localStorage.getItem("adminId")
        try {
            const result = await getAdminDetails({
                id: id,
                "type": "admin"
            })
            if(result.success){
                setAdminDetails(result.data)
            }

        } catch (error) {
            //console.log(error)
        }
    }

    useEffect(() => {
        // getAdminDetailsApiData();
    }, []);

    const reportHandler = () => {
        //console.log('reportHandler');
    }

    const showPostDetail = adminDetails => {
        const POST_MODAL = <AdminDetailEditForm
            userAllData={adminDetails}
            reportHandler={reportHandler}
            isReportBtn={true}
            isModalView={true}
            getAdminDetailsApiData={getAdminDetailsApiData}
        />
        handleModalData(POST_MODAL)
    }

    return (
        <div className="container mt-8 px-8">
            <div className="user_data_admin">
                <div className="grid grid-cols-2 gap-4">
                    <div className="mb-8">
                        <div className="userDataMain px-10">
                            <div className="flex h-40 justify-center flex-col items-center">
                                <div className="w-28 h-28 rounded-full flex items-center justify-center bg-shadowImg imageMarginTop">
                                    <img src={`${BASE_URL_IMG}${adminDetails.image}?${new Date().getTime()}`} alt="User-image" className="w-24 h-24 rounded-full" />
                                </div>
                                <h3 className="text-sidenav capitalize mt-2 text-3xl">{adminDetails.name}</h3>
                                <p className="text-2xl text-paraText border-b border-muteText pb-2">{adminDetails.designation}</p>
                                <p className="text-lg pt-2 capitalize text-muteText">{`${adminDetails.country} , ${adminDetails.region}`}</p>
                            </div>
                            <div className="my-8 border-r border-muteText">
                                <div className="my-2">
                                    <h3 className="text-lg text-sidenav py-2">Party</h3>
                                    <div className="partyBoxShadow w-96 py-5">
                                        <div className="flex items-center px-8">
                                            <img src={`${BASE_URL_IMG}${adminDetails.partyImage}`} alt="party image" className="w-16" />
                                            <p className="pl-6 text-paraText text-sm">{adminDetails.party}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="my-2">
                                    <h3 className="text-lg text-sidenav py-2">Badge</h3>
                                    {
                                        adminDetails.badge && adminDetails.badge.map((data , idx) => {
                                            return (
                                                <div key={idx} className="partyBoxShadow w-96 py-5">
                                                    <div className="flex items-center px-8">
                                                        <img src={`${BASE_URL_IMG}${data.logo}`} alt="party image" className="w-16" />
                                                        <p className="pl-6 text-paraText text-sm">{data.name}</p>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                <div className="my-2 grid grid-cols-3 gap-4">
                                    <h3 className="text-lg text-sidenav py-2">Email</h3>
                                    <span className="text-lg col-span-2 text-paraText">{adminDetails.email}</span>
                                </div>
                                <div className="my-2 grid grid-cols-3 gap-4">
                                    <h3 className="text-lg text-sidenav py-2">Mobile Number</h3>
                                    <span className="text-lg col-span-2 text-paraText">{adminDetails.mobile}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mb-8">
                        <div className="userDataMain px-10">
                            <div className="flex h-40 justify-end">
                                <div className="mt-9">
                                    <button
                                        className="button-1"
                                        onClick={() => showPostDetail(adminDetails)}
                                    >
                                        Edit
                                    </button>
                                </div>
                            </div>
                            <div className="my-8">
                                <div className="my-2">
                                    <h3 className="text-lg text-sidenav py-2">Bio</h3>
                                    <p className="text-lg text-paraText">{adminDetails.bio}</p>
                                </div>
                                <div className="my-2">
                                    <h3 className="text-lg text-sidenav py-2 ">Social Media</h3>
                                    <div className="flex items-center">
                                        <img src={twitterImg} alt="twitter" className="mr-2" />
                                        <img src={facebookImg} alt="facebook" className="mx-2" />
                                        <img src={instagramImg} alt="instagram" className="mx-2" />
                                        <img src={youtubeImg} alt="youtube" className="mx-2" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ManageAcoount