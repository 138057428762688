import { useContext, useEffect, useState } from "react";
import FeedsPoll from "./FeedsPoll";
import FeedsPost from "./FeedsPost";
import { ModalContext } from "../../Context";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Grid from "@material-ui/core/Grid";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { getMethodFunction, postMethodFunction } from "../../Utils/services";
import { ADD_GROUPS_API, SPECIALITY_ROUTES } from "../../Utils/routes";
import { toast } from "react-toastify";
import SmallSpinner from "../Common/SmallSpinner";
const GroupForm = ({ title, post, getFeedData }) => {
  const [formData, setFormData] = useState({});
  const [loader, setLoader] = useState(false);
  const [specilaitydata, setspecialitydata] = useState();


  const context = useContext(ModalContext);
  const { closeModal } = context;
  const handlePostSubmit = async (e) => {
    setLoader(true);

    e.preventDefault();
    const postFormData = new FormData();
    postFormData.append("name", formData.name ? formData.name : "");
    postFormData.append(
      "description",
      formData.description ? formData.description : ""
    );
    postFormData.append("rules", formData.rules ? formData.rules : "");
    specilaitydata?.value?.map((item) => {
      postFormData.append("specialities", item._id ? item._id : "");
    });
    postFormData.append("thumbnail", specilaitydata?.imagedata);

    try {
      const result = await postMethodFunction({
        URLPATH: ADD_GROUPS_API,
        DATA: postFormData,
        TYPE: "body",
        CONTENTYPE: "multipart/form-data",
      });
      console.log(result);
      if (result.res) {
        toast.success(result.res.message);
        closeModal();
        getFeedData();
        setFormData({});
        setspecialitydata();
        setLoader(false);
        closeModal();
        //console.log(result.res, "This is result response")
      }
    } catch (error) {
      setLoader(false);
      //console.log(error);
      toast.error(error.message);
    }
  };

  const onChangeHandler = (e) => {
    e.preventDefault();
    const name = e.target.name;
    const value = e.target.value;
    setFormData({ ...formData, [name]: value });
  };

  useEffect(() => {
    (async () => {
      try {
        const result = await getMethodFunction({ UrlPath: SPECIALITY_ROUTES });
        console.log(result);
        setspecialitydata((prev) => ({
          ...prev,
          data: [result?.res],
        }));
        // setdesignationdata(result?.res);
      } catch (err) {
        // toast.error(err.message);
      }
    })();
  }, []);

  return (
    <>
      <div className="flex items-center justify-center">
        <h3 className="title">{title}</h3>
      </div>

      <form
        noValidate
        autoComplete="off"
        className="w-full"
        onSubmit={handlePostSubmit}
      >
        <div className="grid grid-cols-2 gap-x-24 gap-y-4">
          <div>
            <TextField
              name="name"
              value={formData?.name || ""}
              onChange={(e) => onChangeHandler(e)}
              id="name"
              label="Name"
              className="w-full"
            />
          </div>
          <div>
            <TextField
              name="description"
              value={formData?.description || ""}
              onChange={(e) => onChangeHandler(e)}
              id="description"
              label="Description"
              className="w-full"
            />
          </div>
          <div>
            <TextField
              name="rules"
              value={formData?.rules || ""}
              onChange={(e) => onChangeHandler(e)}
              id="rules"
              label="Rules"
              className="w-full"
            />
          </div>
          <div>
            <Autocomplete
              name="manager"
              multiple={true}
              options={specilaitydata?.data?.[0] || []}
              getOptionLabel={(option) => option.speciality}
              value={specilaitydata?.value?.speciality}
              onChange={(e, newVal) =>
                setspecialitydata((prev) => ({
                  ...prev,
                  value: newVal,
                }))
              }
              renderInput={(params) => (
                <TextField {...params} id="manager" label="Add Specialities" />
              )}
            />
          </div>
          <div className="relative w-auto overflow-hidden">
            <TextField
              id="profilephoto"
              label="Add a Profile Photo"
              value={specilaitydata?.imagedata?.name || ""}
              className="w-full text-xs"
            />

            <input
              type="file"
              accept="image/*"
              className="z-50 opacity-0 absolute bottom-0 right-0 w-50"
              name="image-file"
              onChange={(e) =>
                setspecialitydata((prev) => ({
                  ...prev,
                  imagedata: e.target.files[0],
                }))
              }
            />
            <div className="floating-box">+</div>
          </div>
        </div>

        <div className="flex justify-center mt-10">
        
          <button className="button-1 mx-auto flex gap-2" disabled={loader ? true : false}>
             {loader  && <SmallSpinner Loading={loader} /> }Add Group
          </button>
        </div>
      </form>
      {/* <div className="my-12 flex">
                <div className="flex items-baseline">
                    <img
                        src={
                            postVal ?
                                "../../assets/Compose-active.svg" :
                                "../../assets/Compose.svg"
                        }
                        onClick={() => setPostVal(true)} alt="" className="cursor-pointer" />
                    {postVal && <span className="primary-color font-bold">POST</span>}
                </div>
                <div className="ml-10 flex items-baseline">
                    <img
                        src={
                            !postVal ?
                                "../../assets/Chart-active.svg" :
                                '../../assets/Chart.svg'
                        }
                        onClick={() => setPostVal(false)} alt="" className="cursor-pointer" />
                    {!postVal && <span className="pl-2 primary-color font-bold">POLL</span>}
                </div>
            </div>
            {postVal === true ? <FeedsPost getFeedData ={getFeedData} /> : <FeedsPoll getFeedData ={getFeedData} />} */}
    </>
  );
};

export default GroupForm;
