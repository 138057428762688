import Flagged from "./Components/Flagged";
import Events from "./Components/Events";
import Users from "./Components/Users";

import ManageAcoount from "./Components/ManageAccount";
import Notification from "./Components/Notification";
import GROUPSCMP from "./Components/Groups";
import GroupPreviewCmp from "./Components/Groups/GroupPreview";
import Master from "./Components/Master";
import HubDetailPage from "./Components/Master/HubDetailPage";
import MiniHubDeatilPage from "./Components/Master/MiniHubDetailPage";
import SectionPage from "./Components/Master/SectionPage";
import Hubs from "./Components/Master/Hubs";
export const routes = [
  { path: "/", component: Users },
  { path: "users", component: Users },
  { path: "notification", component: Notification },
  { path: "group", component: GROUPSCMP },
  {
    path: "group/:slug",
    component: GroupPreviewCmp,
  },

  { path: "reported", component: Flagged },
  // { path: "master", component: },
  { path: "feeds", component: GROUPSCMP },
  { path: "events", component: Events },
  { path: "profile", component: ManageAcoount },
  { path: "master", component: Master },
  { path: "master/:slug", component: HubDetailPage },
  { path: "master/:slug/:slug1", component: MiniHubDeatilPage },
  { path: "master/:slug/:slug1/:slug2", component: SectionPage },
];
