import { AppBar, Button, Tab, Tabs } from "@material-ui/core";
import plusIcon from "../../Assets/images/icons/plus.svg";
import classnames from "classnames";
import TrueIcon from "../../Assets/images/icons/trueicon.svg";

const InnerHeader = ({
  tabsData,
  handleModalForm,
  tabsValue,
  handlerTabs,
  a11yProps,
  requestPage,
  formBtn,
}) => {
  return (
    <AppBar position="static" className="bg-white inner-header">
      <Tabs
        value={tabsValue}
        onChange={handlerTabs}
        aria-label="users tabs"
        className="px-10 bg-white relative"
      >
        {tabsData.map((obj, index) => (
          <Tab
            key={index}
            label={obj.label}
            className={classnames({
              "custom-tab-btn": true,
              active: tabsValue === index,
            })}
            {...a11yProps(index)}
          />
        ))}
        {formBtn && tabsData[tabsValue].label !== "users" &&  (
          <span
            className="absolute  flex items-center justify-center right-8 addtext top-1.5 font-bold"
            onClick={handleModalForm}
          >
            {`${
              tabsData[tabsValue].label !== "GENERAL" &&
              tabsData[tabsValue].label !== "HUBS / MINI HUBS" &&
              tabsData[tabsValue].label !== "EVENT"
                ? " Add "
                : " "
            }`}
            {tabsData[tabsValue].label}{" "}
            {(tabsData[tabsValue].label === "GENERAL" && "Notification") ||
              (tabsData[tabsValue].label === "HUBS / MINI HUBS" &&
                "Notification") ||
              (tabsData[tabsValue].label === "EVENT" && "Notification")}
            <img src={plusIcon} className="addicon" alt="" />
          </span>
        )}

        {requestPage && (
          <span className="absolute lg:right-28 right-24 w-9 top-1.5 cursor-pointer">
            <Button variant="outlined" className="approval_button_style">
              <img src={TrueIcon} alt="true" />{" "}
              <span className="pl-1">Approve All</span>
            </Button>
          </span>
        )}
      </Tabs>
    </AppBar>
  );
};

export default InnerHeader;
