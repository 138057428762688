import React, { useState, useContext, useEffect } from "react";
import {
  USER_FORM,
  INTERNATIONAL_OFFICER_USER_FORM,
  EVENT_FORM,
} from "../ModalTemplate";
import { TabPanel } from "../Common/TabPanel";
import InnerHeader from "../Common/InnerHeader";
import { ModalContext } from "../../Context";
import {
  getEventData,
  getMethodFunction,
  postMethodFunction,
} from "../../Utils/services";
import AllEvent from "./AllEvent";
import AddEventForm from "../ModalTemplate/AddEventForm";
import Loader from "../Common/Loader";
import { GET_METHOD_EVENTS } from "../../Utils/routes";
import { toast } from "react-toastify";

const a11yProps = (index) => {
  return {
    id: `flagged-tab-${index}`,
    "aria-controls": `flagged-tabpanel-${index}`,
  };
};

const Events = () => {
  const [tabsValue, setTabsValue] = useState(0);
  const [eventAllData, setEventAllData] = useState([]);
  const [liData, setLiData] = useState([]);
  const [minihubData, setMinihubData] = useState([]);
  const context = useContext(ModalContext);
  const { handleModalData } = context;
  const [timeData, setTimeData] = useState(new Date());
  const [loader, setLoader] = useState(true);

  const getEventApiData = async (year, month) => {
    setLoader(false);
    const postformdata = {
      year: year,
      month: month,
    };
    try {
      const result = await postMethodFunction({
        URLPATH: GET_METHOD_EVENTS,
        DATA: postformdata,
        TYPE: "body",
      });
      console.log(result);
      setLoader(false);

      setEventAllData(result?.res?.data?.AllEvent);
    } catch (err) {
      toast.error(err.message);
      setLoader(false);
    }
  };
  const handlerTabs = (event, newValue) => {
    setTabsValue(newValue);
  };

  const tabsData = [
    {
      label: "Event",
      data: (
        <>
          <Loader loader={loader} />
          <AllEvent
            getEventApiData={getEventApiData}
            handleModalData={handleModalData}
            eventAllData={eventAllData}
          />
        </>
      ),
    },
  ];

  const handleModalForm = () => {
    switch (tabsValue) {
      case 0:
        handleModalData(
          <AddEventForm
            getEventApiData={getEventApiData}
            title="Add Event"
            buttonText="Add Event"
            eventAllData={eventAllData}
          />
        );
        break;

      case 1:
        handleModalData(
          <AddEventForm
            getEventApiData={getEventApiData}
            title="Add LI Event"
            buttonText="Add LI Event"
            eventAllData={eventAllData}
          />
        );
        break;

      default:
        handleModalData(
          <AddEventForm
            getEventApiData={getEventApiData}
            title="Add MINI HUB Event"
            buttonText="Add MINI HUB Event"
            eventAllData={eventAllData}
          />
        );
        break;
    }
  };

  return (
    <div className="relative">
      <InnerHeader
        tabsData={tabsData}
        handleModalForm={handleModalForm}
        tabsValue={tabsValue}
        handlerTabs={handlerTabs}
        a11yProps={a11yProps}
        formBtn={false}
      />

      {tabsData.map((obj, index) => (
        <TabPanel className="px-10" value={tabsValue} index={index} key={index}>
          {obj.data}
        </TabPanel>
      ))}
    </div>
  );
};

export default Events;
