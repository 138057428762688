import PostDetail from "./Components/Common/PostDetail";
import YoutubeVideoPost from "./Components/Common/YoutubeVideoPost";
import FilePost from "./Components/Common/FilePost";
import ArticlePost from "./Components/Common/ArticlePost";
import PostPolling from "./Components/Common/PostPolling";
import VideoPlayer from "./Components/Common/VideoPlayer";

export const PollPercentageCalculation = (first, second) => {
  let sum = 0;
  for (let i = 0; i < first.length; i++) {
    sum += first[i].count;
  }

  let resulteddatamultiple = second / sum;
  let percentage = resulteddatamultiple * 100;

  return isNaN(percentage) ? 0 : Math.floor(percentage);
};

export const filterPostType = (
  data,
  onClickHandler,
  isModalView = false,
  isReportBtn = false
) => {
  const isMedia = data && data.media != null && data.media.length > 0;
  // //console.log(data, isModalView, isReportBtn);

  if (data && data.isPoll) {
    return renderPost("poll", data, onClickHandler, isModalView, isReportBtn);
  } else if (isMedia) {
    const type = data.media[0].mediaType;
    return renderPost(type, data, onClickHandler, isModalView, isReportBtn);
  } else {
    return renderPost(
      "articlePost",
      data,
      onClickHandler,
      isModalView,
      isReportBtn
    );
  }
};

export const renderPost = (
  type,
  data,
  onClickHandler,
  isModalView,
  isReportBtn
) => {
  switch (type) {
    case "visual":
      return (
        <PostDetail
          data={data}
          onClickHandler={onClickHandler}
          isReportBtn={isReportBtn}
          isModalView={isModalView}
        />
      );

    case "link":
      return (
        <YoutubeVideoPost
          data={data}
          onClickHandler={onClickHandler}
          isReportBtn={isReportBtn}
          isModalView={isModalView}
        />
      );
    case "video":
      return (
        <VideoPlayer
          data={data}
          onClickHandler={onClickHandler}
          isReportBtn={isReportBtn}
          isModalView={isModalView}
        />
      );

    case "file":
      return (
        <FilePost
          data={data}
          onClickHandler={onClickHandler}
          isReportBtn={isReportBtn}
          isModalView={isModalView}
        />
      );

    case "articlePost":
      return (
        <ArticlePost
          data={data}
          onClickHandler={onClickHandler}
          isReportBtn={isReportBtn}
          isModalView={isModalView}
        />
      );

    case "poll":
      return (
        <PostPolling
          data={data}
          onClickHandler={onClickHandler}
          isReportBtn={isReportBtn}
          isModalView={isModalView}
        />
      );
  }
};

export const detectBrowserAndDevice = () => {
  const UserAgent = navigator.userAgent;
  const device = {
    iPad: /iPad/.test(UserAgent),
    iPhone: /iPhone/.test(UserAgent),
    Android: /Android/.test(UserAgent),
    Windows: /Windows/.test(UserAgent),
    macOS: /Macintosh/.test(UserAgent),
    Linux: /Linux/.test(UserAgent),
    BlackBerry: /BlackBerry/.test(UserAgent),
    iPod: /iPod/.test(UserAgent),
    Samsung: /Samsung/.test(UserAgent),
    Nokia: /Nokia/.test(UserAgent),
    Sony: /Sony/.test(UserAgent),
    HTC: /HTC/.test(UserAgent),
    LG: /LG/.test(UserAgent),
    Motorola: /Motorola/.test(UserAgent),
    Nexus: /Nexus/.test(UserAgent),
    Kindle: /Kindle/.test(UserAgent),
    FirePhone: /Fire Phone/.test(UserAgent)
  };

  let browserName;

  // Checking for browser
  if (/Firefox\//.test(UserAgent)) {
    browserName = "Mozilla Firefox";
  } else if (/Edg\//.test(UserAgent)) {
    browserName = "Microsoft Edge";
  } else if (/Chrome\//.test(UserAgent)) {
    browserName = "Google Chrome";
  } else if (/Safari\//.test(UserAgent)) {
    browserName = "Apple Safari";
  } else if (/OPR\//.test(UserAgent)) {
    browserName = "Opera";
  } else if (/Trident\//.test(UserAgent)) {
    browserName = "Microsoft Internet Explorer";
  } else if (/UCBrowser\//.test(UserAgent)) {
    browserName = "UC Browser";
  } else if (/SamsungBrowser\//.test(UserAgent)) {
    browserName = "Samsung Internet";
  } else if (/MQQBrowser\//.test(UserAgent)) {
    browserName = "QQ Browser";
  } else if (/WeChat\//.test(UserAgent)) {
    browserName = "WeChat Browser";
  } else if (/Yandex\//.test(UserAgent)) {
    browserName = "Yandex Browser";
  } else if (/AppleWebKit\//.test(UserAgent)) {
    browserName = "WebKit-based Browser (unknown)";
  } else {
    browserName = "Unknown Browser";
  }
  var navigator_info = window.navigator;
  var screen_info = window.screen;
  var uid = navigator_info.mimeTypes.length;
  uid += navigator_info.userAgent.replace(/\D+/g, '');
  uid += navigator_info.plugins.length;
  uid += screen_info.height || '';
  uid += screen_info.width || '';
  uid += screen_info.pixelDepth || '';
  // Constructing the result object
  const result = {
    browser: browserName,
    device: Object.keys(device).find(dev => device[dev]) || "Unknown Device",
    deviceId: uid
  };

  return result;
}



