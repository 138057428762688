import React, { Fragment, useEffect, useMemo } from "react";
import FeedsTable from "../Groups/FeedsTable";
import { BASE_URL_IMG } from "../../Utils/services";

const NotificationDataCmp = ({ data, getNotificationData }) => {
  useEffect(() => {
    getNotificationData();
  }, []);


  const Notification_COlumsn = useMemo(
    () => [
      {
        Header: "Thumbnail",
        accessor: (data) => {
          return (
            <div
              // onClick={() => groupviewhandler(data)}
              className="max-w-[100px] max-h-[100px]"
            >
              {" "}
              {data?.image !== null ?? (
                <img
                  src={`${BASE_URL_IMG}/general/${data?._id}/${data?.image}`}
                  alt="img"
                />
              )}
            </div>
          );
        },
      },
      {
        Header: "Title",
        accessor: (data) => {
          return (
            <div
              //   onClick={() => groupviewhandler(data)}
              className="max-w-[100px] max-h-[100px]"
            >
              {data?.title}
            </div>
          );
        },
      },
      {
        Header: "Description",
        accessor: "description",
      },
      {
        Header: "Created By",
        accessor: (data) => {
          return (
            <div>
              {data?.createdBy?.firstname} {data?.createdBy?.lastname}
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <Fragment>
      {data && <FeedsTable columns={Notification_COlumsn} data={data ?? []} />}
    </Fragment>
  );
};

export default NotificationDataCmp;
