import { useContext, useEffect, useMemo } from "react";
import Pagination from "@material-ui/lab/Pagination";
import FeedsTable from "./FeedsTable";
import flag from "../../Assets/images/flag.svg";
import { ModalContext } from "../../Context";
import PostDetails from "../ModalTemplate/PostDetails";
import { filterPostType } from "../../functions";
import ReactPlayer from "react-player";
import { useState } from "react";
import { BASE_URL_IMG } from "../../Utils/services";
import RemoveConfirmation from "../ModalTemplate/RemoveConfirmation";
import modalTextsFile from "../ModalTemplate/modalTextsFile";
import { deleteManageFlag } from "../../Utils/services";
import imagereload from "../../Assets/images/request/imagereload.webp";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
const formatDate = (date) => {
  const _date = new Date(date);
  const month = _date.getMonth() + 1;
  const year = _date.getFullYear();
  const newdate = _date.getDate();
  return `${newdate}/${month}/${year}`;
};

const GROUPDATA = ({ data, getgroupData, totalCount }) => {
  const [page, setPage] = useState(1);
  const [postsPerPage] = useState(10);
  const [totalPageToShow, setTotalPageToShow] = useState(1);
  const navigate = useNavigate();

  const context = useContext(ModalContext);

  useEffect(() => {
    if (totalCount && totalCount > 0) {
      setTotalPageToShow(Math.ceil(totalCount / postsPerPage));
    }
    getgroupData({
      page: page,
      size: postsPerPage,
    });
  }, [page, totalCount]);

  const groupviewhandler = (data) => {
    console.log(data);
    navigate(`${data?._id}`, {
      state: {
        value: data,
      },
    });
  };

  const FeedsColumns = useMemo(
    () => [
      {
        Header: "Thumbnail",
        accessor: (data) => {
          return (
            <div
              onClick={() => groupviewhandler(data)}
              className="max-w-[100px] max-h-[100px]"
            >
              {" "}
              {!data?.thumimage ? (
                <img
                  src={imagereload}
                  alt="img"
                 
                />
              ) : (
                <img
                  src={`${BASE_URL_IMG}/groups/${data?._id}/${data?.thumimage}`}
                  alt="img"
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = imagereload;
                  }}
                />
              )}
            </div>
          );
        },
      },
      {
        Header: "Group Name",
        accessor: (data) => {
          return (
            <div onClick={() => groupviewhandler(data)}>{data?.group_name}</div>
          );
        },
      },
      {
        Header: "Description",
        accessor: "description",
      },
      {
        Header: "No of Members",
        accessor: "members",
      },
    ],
    []
  );

  return (
    <>
      {/* <h2>Welcome to group section</h2> */}
      {/* <div className="flex justify-between items-center">
        <Pagination
          count={totalPageToShow ? totalPageToShow : 10}
          variant="outlined"
          shape="rounded"
          className="flex items-center"
          onChange={handlePageChange}
          page={page}
        />
      </div> */}
      {data && <FeedsTable columns={FeedsColumns} data={data} />}
    </>
  );
};

export default GROUPDATA;
