import { useContext, useState } from "react";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import AntSwitch from "../Common/AntSwitch";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { RadioGroup, FormControlLabel, Radio } from "@material-ui/core";
import { useEffect } from "react";
import {
  regionCountryList,
  partyList,
  addAdmin,
  allUsers,
  getHubs,
  getExpertise,
  postMethodFunction,
} from "../../Utils/services";
import { toast } from "react-toastify";
import { Chip } from "@material-ui/core";
import { ModalContext } from "../../Context";
import Loader from "../Common/Loader";
import { ADD_ADMIN } from "../../Utils/routes";
import SmallSpinner from "../Common/SmallSpinner";

const _helperText = {
  badge:
    "*start typing, the list will show up for multiple selects - Press enter to make an entry and then again start typing for selecting the second option",
  expertise:
    "*start typing, the list will show up for multiple selects - Press enter to make an entry and then again start typing for selecting the second option",
};

const AdminForm = ({ title, buttonText, refreshDataMethod }) => {
  const [formData, setFormData] = useState({});
  const [loader, setLoader] = useState(false);
  const context = useContext(ModalContext);
  const { closeModal } = context;
  const onChangeHandler = (e) => {
    e.preventDefault();
    const name = e.target.name;
    const value = e.target.value;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);

    const admindata = {
      firstname: formData.FirstName,
      lastname: formData.LastName,
      adminType: 0,
      email: formData.email && formData.email.toLowerCase(),
      mobile: formData.mobile,
      password: formData.mobile,
      dob: new Date(formData.Dob),
    };

    try {
      const results = await postMethodFunction({
        URLPATH: ADD_ADMIN,
        DATA: admindata,
        TYPE: "body",
      });
      //console.log(results)
      if (results.res._id) {
        toast.success(results.res.message);
        setLoader(false);
        closeModal();
        //console.log('"Admin');
        refreshDataMethod();
      }
    } catch (e) {
      setLoader(false);
      //console.log(e);
      toast.error(e.messages ?? e.message);
    }
  };

  function dynamicSort(property) {
    var sortOrder = 1;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      var result =
        a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
      return result * sortOrder;
    };
  }

  return (
    <>
      {/* <Loader loader={loader} /> */}
      <div className="flex items-center justify-center">
        <h3 className="title"> add new admin </h3>
      </div>

      <form
        // noValidate
        autoComplete="off"
        className="w-full"
        onSubmit={handleSubmit}
      >
        <div className="grid grid-cols-2 gap-x-24 gap-y-4">
          <TextField
            label="FirstName"
            name="FirstName"
            value={formData.FirstName || ""}
            onChange={(e) => onChangeHandler(e)}
          />
          <TextField
            label="LastName"
            name="LastName"
            value={formData.LastName || ""}
            onChange={(e) => onChangeHandler(e)}
          />

          <TextField
            label="Email"
            name="email"
            value={formData.email || ""}
            onChange={(e) => onChangeHandler(e)}
          />

          <TextField
            label="Mobile No"
            name="mobile"
            value={formData.mobile || ""}
            onChange={(e) => onChangeHandler(e)}
          />
          <TextField
            label="D.O.B"
            name="Dob"
            type="date"
            value={formData.Dob || ""}
            onChange={(e) => onChangeHandler(e)}
          />
          <TextField
            label="Password"
            name="password"
            type="password"
            value={formData.password || ""}
            onChange={(e) => onChangeHandler(e)}
          />
        </div>

        <div className="flex justify-center mt-10">
          <button
            className="button-1 flex gap-2 mx-auto"
            type="submit"
            disabled={loader ? true : false}
          >
            {loader  && <SmallSpinner Loading={loader} /> } add admin
          </button>
        </div>
      </form>
    </>
  );
};

export default AdminForm;
