import logo from "../../Assets/images/Curbside.png";
import { changePasswordApi, postMethodFunction } from "../../Utils/services";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useLocation } from "react-router";
import { Link, useNavigate } from "react-router-dom";
import authBg from "../../Assets/images/banner_new.jpg";
import { CHANGE_PASSWORD_API } from "../../Utils/routes";

const ChangePassword = () => {
  const [newPassword, setNewPassword] = useState("");
  const [newPassword2, setNewPassword2] = useState("");
  const [emailValue, setEmailValue] = useState("");
  const [tokenValue, setTokenValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const location = useLocation();
  const navigate = useNavigate();

  console.log(location);

  useEffect(() => {
    if (location.state !== undefined) {
      const { email, token } = location.state;
      ////console.log(email, token, "Email, Location");
      setEmailValue(email);
      //   setTokenValue(token);
    }
    if (location.state === undefined) {
      navigate("/login");
    }
  }, []);

  const handleValidation = () => {
    let _errors = {};
    let formIsValid = true;
    let lowerCaseLetters = /[a-z]/g;
    let upperCaseLetters = /[A-Z]/g;
    let numbers = /[0-9]/g;
    let specialCharaceter = /[!@#\$%\^&\*_]/;
    if (!newPassword) {
      formIsValid = false;
      _errors["password"] = "Password can not be empty";
    } else if (!newPassword.match(lowerCaseLetters)) {
      formIsValid = false;
      _errors["password"] = "Password should be atleast one lowercase";
    } else if (!newPassword.match(upperCaseLetters)) {
      formIsValid = false;
      _errors["password"] = "Password should be atleast one Upercase";
    } else if (!newPassword.match(numbers)) {
      formIsValid = false;
      _errors["password"] = "Password should be atleast one number";
    } else if (newPassword.length < 8) {
      formIsValid = false;
      _errors["password"] = "Password should be 8 charaters";
    } else if (!newPassword.match(specialCharaceter)) {
      formIsValid = false;
      _errors["password"] = "Password should be atleast one special character";
    } else if (newPassword !== newPassword2) {
      formIsValid = false;
      _errors["password2"] = "Password are not match";
    }
    setErrors(_errors);
    return formIsValid;
  };

  const handleChangePassword = async (e) => {
    e.preventDefault();
    if (handleValidation()) {
      setLoading(true);

      const postformdata = {
        email: location.state?.email,
        newpwd: newPassword2,
      };
      try {
        const result = await postMethodFunction({
          URLPATH: CHANGE_PASSWORD_API,
          DATA: postformdata,
          TYPE: "body",
        });
        if (result.res.status === 200) {
          setLoading(false);
          toast.success(result.res.message);
          navigate("/login");
        }
      } catch (error) {
        setLoading(false);
        toast.error(error.message);
        //console.log(error);
      }
    }
  };

  return (
    <div className="w-full relative">
      <img src={authBg} alt="" />
      <div className="flex absolute top-1/2 left-0 w-full">
        <div className="w-2/5 mx-auto auth-container">
          <div className="w-1/2 mx-auto text-center">
            <img src={logo} className="w-1/2 mx-auto mb-10" alt="logo" />

            <h2 className="primary-color font-sans text-2xl	 font-bold mb-10">
              Change PASSWORD ?
            </h2>

            <form className="auth-form" onSubmit={handleChangePassword}>
              <div className="password_inputField_div">
                <input
                  type="password"
                  id="newPassword"
                  className="block input p-2 "
                  placeholder="New Password"
                  name="email"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
                {errors.password && (
                  <p className="text-sm text-left text-red-800">
                    {errors.password}
                  </p>
                )}
              </div>
              <div className="password_inputField_div">
                <input
                  type="password"
                  id="matchPassword"
                  className="block input p-2 w-full"
                  placeholder="Confirm Password"
                  name="newpassword2"
                  value={newPassword2}
                  onChange={(e) => setNewPassword2(e.target.value)}
                />
                {errors.password2 && (
                  <p className="text-sm text-left text-red-800">
                    {errors.password2}
                  </p>
                )}
              </div>
              {loading ? (
                <p>Loading...</p>
              ) : (
                <button className="primary-btn" type="submit">
                  Change
                </button>
              )}
              <span className="text-btn">
                <Link to="/login">Remember password ?</Link>
              </span>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
