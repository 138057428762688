import logo from "../../Assets/images/Curbside.png";
import {
  forgotPasswordApi,
  loginService,
  postMethodFunction,
} from "../../Utils/services";
import { useState, useContext, useEffect } from "react";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import authBg from "../../Assets/images/banner_new.jpg";
import { FORGOT_OTP_API } from "../../Utils/routes";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const handleForgetPassword = async (e) => {
    setLoading(true);
    // navigate("/otp");
    // return;
    const postformdata = {
      email: email,
    };
    e.preventDefault();
    try {
      const result = await postMethodFunction({
        URLPATH: FORGOT_OTP_API,
        DATA: postformdata,
        TYPE: "body",
      });
      console.log(result);
      if (result.res.status === 200) {
        setLoading(false);
        toast.success(result.res.message);

        navigate("/otp", {
          state: {
            verification_key: result?.res,
            email: email,
          },
        });
      }
    } catch (error) {
      setLoading(false);
      toast.error(error.message);
      //console.log(error);
    }
  };

  return (
    <div className="w-full relative">
      <img src={authBg} alt="" />
      <div className="flex absolute top-1/2 left-0 w-full">
        <div className="w-2/5 mx-auto auth-container">
          <div className="w-1/2 mx-auto text-center">
            <img src={logo} className="w-1/2 mx-auto mb-10" alt="logo" />

            <h2 className="primary-color font-sans text-2xl	 font-bold mb-10">
              FORGOT PASSWORD ?
            </h2>

            <form className="auth-form" onSubmit={handleForgetPassword}>
              <input
                type="email"
                placeholder="Email ID"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              {loading ? (
                <p>Loading...</p>
              ) : (
                <button className="primary-btn" type="submit">
                  Send
                </button>
              )}

              <span className="text-btn">
                <Link to="/login">Remember password ?</Link>
              </span>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
