import React, { Fragment, Suspense } from "react";
import { Route, Routes, useParams, useNavigate } from "react-router-dom";
import { routes } from "../../routes";
import Home from "../Home";

const MainWrapper = ({ match, handleModalData, isModalOpen, modalData }) => {
  return (
    <Fragment>
      <Suspense fallback={<div className="mt-[25%]">...</div>}>
        <Routes>
          <Route path="/user" element={<div>hi</div>} />

          {routes.map((route) => (
            <Route
              key={route.path}
              path={`${route.path}`}
              element={
                <route.component
                  modalData={modalData}
                  handleModalData={handleModalData}
                  isModalOpen={isModalOpen}
                />
              }
            />
          ))}
        </Routes>
      </Suspense>
    </Fragment>
  );
};

export default MainWrapper;
