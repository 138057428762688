import axios from "axios";
import { REFRESH_TOKEN } from "./routes";
import { toast } from "react-toastify";
import { detectBrowserAndDevice } from "../functions";
import { postMethodFunction } from "./services";
import { useContext } from "react";
// import AuthContext from "./auth-context";
// import AuthContext from "./auth-context";

const NetworkServices = () => {
  // console.log(AuthContext, "line no 11");
  // const authCtx = useContext(AuthContext);
  // const authCtx = useContext(AuthContext);

  // Add a request interceptor
  axios.interceptors.request.use(
    (config) => {
      if (config.url !== "/auth/refresh") {
        const access = localStorage.getItem("token");
        if (access) {
          config.headers["Authorization"] = "Bearer " + access;
        }
        return config;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  // Add a response interceptor
  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    async function (error) {
      const originalRequest = error.config;
      const refresh = localStorage.getItem("refreshToken");
      if (error.response) {
        if (error.response.data.message) {
          // Catching axios errors
          //catches if the session ended!
          if (error.config.url === "refresh" && error.response.status === 401) {
            localStorage.removeItem("token");
            localStorage.clear();
            toast.error("session expire login again");
            window.location.href = "/login";
          }
          if (error.response.data.message.includes("jwt expired")) {
            // old
            // if (error.response.data.status === 401) { //new

            originalRequest._retry = true;
            const data = {
              refreshToken: refresh,
              deviceInfo: {
                deviceId: detectBrowserAndDevice().deviceId,
                deviceName:
                  detectBrowserAndDevice().device +
                  " " +
                  detectBrowserAndDevice().browser,
              },
            };
            const response = await axios.post(REFRESH_TOKEN, data);
            window.localStorage.setItem(
              "token",
              response.data.data.accessToken
            );
            axios.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${response.data.data.accessToken}`;
            return axios(originalRequest);
          }
        }
        if (error.response.data.status === 401) {
          const response = await await postMethodFunction({
            URLPATH: "refresh",
            DATA: {
              refreshToken: localStorage?.getItem("refreshToken"),
              deviceInfo: {
                deviceId: detectBrowserAndDevice().deviceId,
                deviceName:
                  detectBrowserAndDevice().device +
                  " " +
                  detectBrowserAndDevice().browser,
              },
            },
            TYPE: "body",
          });

          console.log(response, "response line no 92");

          if (response?.res?.success) {
            // toast.success(result.res.message || "User Authenticated!");
            // authCtx.login(
            //   response.res.data.accessToken,
            //   localStorage?.getItem("refreshToken"),
            //   true
            // );

            localStorage.setItem("token", response.res.data.accessToken);

            originalRequest._retry = true;
            return axios(originalRequest);
          } else {
            localStorage.removeItem("token");
            localStorage.clear();
            toast.error("session expire login again");
            window.location.href = "/login";
          }
        }
      }
      return Promise.reject(error);
    }
  );
};
export default NetworkServices;
