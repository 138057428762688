import Table from "../../Common/Table";
import { useMemo } from "react";
import { useEffect } from "react";
import { getMethodFunction } from "../../../Utils/services";
import { PROMP_API_URL } from "../../../Utils/routes";
import { toast } from "react-toastify";

const PROMPTDATACOMPONENT = ({ SETPROMPTDATA, PROMPTDATA }) => {
  const PROMPT_DATA_COLUMN = useMemo(
    () => [
      {
        Header: "PROMPT NAME",
        accessor: "promptName",
      },
    ],
    []
  );

  useEffect(() => {
    (async () => {
      try {
        const result = await getMethodFunction({ UrlPath: PROMP_API_URL });

        SETPROMPTDATA(result?.res);
      } catch (err) {
        toast.error(err.message);
      }
    })();
  }, []);
  return (
    <>
      {PROMPTDATA && (
        <Table columns={PROMPT_DATA_COLUMN} data={PROMPTDATA || []} />
      )}
    </>
  );
};

export default PROMPTDATACOMPONENT;
