import Auth from "./Auth";
import { Route, useNavigate, Routes, Navigate } from "react-router-dom";
import Layout from "./Layout";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AuthContext from "../Utils/auth-context";
import { useContext, useEffect } from "react";
import ForgotPassword from "./Auth/ForgotPassword";
import ChangePassword from "./Auth/ChangePassword";
import OtpVerification from "./Auth/OtpVerification";
// import jwtDecode from "jwt-decode";
import LoginOtpVerification from "./Auth/LoginOtpVerification";

const App = () => {
  const navigage = useNavigate();
  const authCtx = useContext(AuthContext);
  console.log(authCtx);

  // for jwt expires
  // if (authCtx.token) {
  //   const { exp } = jwtDecode(authCtx.token)
  //   const expirationTime = (exp * 1000) - 60000
  //   if (Date.now() >= expirationTime) {
  //     // localStorage.clear();
  //     history.push("/login");
  //   }
  // }
  // for jwt expires
  console.log(authCtx, "line no 28");
  useEffect(() => {
    const initialToken = localStorage.getItem("token");

    if (!!initialToken) {
      // debugger
      // navigage("/");
    }
  }, []);

  return (
    <>
      {/* <div onClick={() => navigage("/home")}>hello</div> */}
      <ToastContainer position="top-right" autoClose={3000} />
      <Routes>
        {/* <Route path="/*" element={<Layout />} /> */}

        <Route path="/login" element={<Auth />} />
        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route path="/changepassword" element={<ChangePassword />} />
        <Route path="/otp" element={<OtpVerification />} />
        <Route path="/loginOtp" element={<LoginOtpVerification />} />
        {/* <Route path="/*" element={<Layout />} /> */}
        {authCtx.isLoggedIn ? (
          <Route path="/*" element={<Layout />} />
        ) : (
          <Route path="/login" element={<Auth />} />
        )}
        <Route path="*" element={<Navigate to="/login" replace />} />
      </Routes>

      {/* <h1>welome to home page</h1> */}
    </>
  );
};

export default App;
