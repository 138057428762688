import axios from "axios";
import {
  LOGIN,
  ADD_ADMIN,
  REGION_COUNRTY_LIST,
  BADGES,
  PARTIES,
  ALL_USER,
  GET_COUNTRIES,
  GET_REGIONS,
  GET_PARTIES,
  RESET_PASSWORD,
  GET_HUBS,
  GET_EXPERTISE,
  ADD_EXPERTISE,
  ADD_COUNTRY,
  EDIT_EXPERTISE,
  DELETE_EXPERTISE,
  ADD_REGION,
  DELETE_REGION,
  EDIT_REGION,
  DELETE_COUNTRY,
  EDIT_COUNTRY,
  ADD_HUB,
  GET_SINGLE_HUB,
  DELETE_HUB,
  ADD_MINI_HUB,
  GET_SINGLE_MINI_HUB,
  DELETE_MINI_HUB,
  ADD_SECTION,
  GET_SINGLE_SECTION,
  ADD_AFFILIATION,
  DELETE_AFFLIATION,
  ADD_USER,
  GET_SINGLE_PARTY,
  EDIT_AFFILIATION,
  GET_FEED,
  ADD_POST,
  ADD_FEED_POST,
  GET_MEMBERS,
  GET_MEMBERS_BY_ID,
  ADD_FEED_POLL,
  GET_ADMIN_DATA,
  HOME_DATA,
  GET_MANAGE_FLAG,
  KEEP_POST,
  DELETE_MANAGE_FLAG,
  GET_REMOVED_FLAG,
  FLAG_REINSTATE,
  EVENT_DATA,
  ADD_EVENT,
  GET_MINIHUBS,
  EDIT_ADMIN,
  CHANGEPASSWORD_API,
  OTPVERIFICATION_API,
  FORGOTPASSWORD_API,
  ADMIN_DETAILS,
  EDIT_HUB,
  GET_REQUESTDATA,
  DIRECTORY_ACCESS,
  APPROVE_PEOPLE,
  DECLINE_PEOPLE,
  APPROVE_Calendar,
  EDIT_MINIHUB,
  EDIT_SECTION,
  EDIT_USER,
  FEEDS_SINGLEPOST,
  GET_GENERAL_NOTIFICATION,
  ADD_GENERAL_NOTIFICATION,
  GET_ORGANIZATION,
  GET_THINKTANK,
  ADD_RESOURCES,
  INVITE_NOTIFICATION,
  INVITE_ALL_NOTIFICATION,
  EDIT_EVENT,
  REMOVE_EVENT,
  EDIT_RESOURCES,
  DELETE_RESOURCES,
  FEED_GET_POLL,
  FEED_GET_POST,
  NON_HUB_MEMBERS,
  ADD_NON_HUB_MEMBERS,
  MAINTENANCE_MODE,
  DEACTIVATE_USER,
  REMOVE_MEMBERS_HUB,
  PARTY_DISABLED,
  OTPVERIFICATION_API_LOGIN,
  LOGIN_FIRST,
} from "./routes";

export const BASE_URL = "https://api.thecurbsideapp.com/admin/"; // For production
// export const BASE_URL = "https://curbside.techtonic.asia/admin/"; //For Testing

export const BASE_URL_IMG =
  "https://api.thecurbsideapp.com/uploads"; // For production

  // export const BASE_URL_IMG =
  // "https://d3kaps6hqweamf.cloudfront.net/uploads";  //For Testing

axios.defaults.baseURL = BASE_URL;
export const loginService = async (data) => {
  try {
    const response = await axios.post(LOGIN, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const loginServiceFirst = async (data) => {
  try {
    const response = await axios.post(LOGIN_FIRST, data, {
      headers: { "Content-Type": "application/json" },
    });

    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const changePasswordApi = async (data) => {
  try {
    const response = await axios.post(CHANGEPASSWORD_API, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const otpVerificationApi = async (data) => {
  try {
    const response = await axios.post(OTPVERIFICATION_API, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const otpVerificationLoginApi = async (data) => {
  try {
    const response = await axios.post(OTPVERIFICATION_API_LOGIN, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const forgotPasswordApi = async (data) => {
  try {
    const response = await axios.post(FORGOTPASSWORD_API, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const addAdmin = async (data) => {
  try {
    const response = await axios.post(ADD_ADMIN, data);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const EditAdmin = async (data) => {
  try {
    const response = await axios.post(EDIT_ADMIN, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const addUser = async (data) => {
  try {
    const response = await axios.post(ADD_USER, data);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const editUser = async (data) => {
  try {
    const response = await axios.post(EDIT_USER, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const getEventData = async (data) => {
  try {
    const response = await axios.post(EVENT_DATA, data);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const getMinihubs = async (_id) => {
  try {
    const response = await axios.get(`${GET_MINIHUBS}/${_id}`);
    if (response.status === 200) {
      return { res: response.data.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const InviteNotification = async ({ people, id }) => {
  try {
    const response = await axios.post(`${INVITE_NOTIFICATION}/${people}/${id}`);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const InviteAllPeopleNotification = async ({ people }) => {
  try {
    const response = await axios.post(`${INVITE_ALL_NOTIFICATION}/${people}`);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const addEvent = async (data) => {
  try {
    const response = await axios.post(ADD_EVENT, data);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const editEvent = async (data) => {
  try {
    const response = await axios.post(EDIT_EVENT, data);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const DeleteEvent = async (id) => {
  try {
    const response = await axios.delete(`${REMOVE_EVENT}/${id}`);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const getHomeApiData = async () => {
  try {
    const response = await axios.get(HOME_DATA);
    if (response.status === 200) {
      return { res: response.data.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const regionCountryList = async () => {
  try {
    const response = await axios.get(REGION_COUNRTY_LIST);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const getRequestDataApi = async () => {
  try {
    const response = await axios.get(GET_REQUESTDATA);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const badgeList = async () => {
  try {
    const response = await axios.get(BADGES);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const partyList = async () => {
  try {
    const response = await axios.get(PARTIES);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const allUsers = async () => {
  try {
    const response = await axios.get(ALL_USER);
    if (response.status === 200) {
      return { res: response.data.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const allAdminUsers = async () => {
  try {
    const response = await axios.get(GET_ADMIN_DATA);
    return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const getAdminDetails = async (data) => {
  try {
    const response = await axios.post(ADMIN_DETAILS, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const approvePeopleApi = async (data) => {
  try {
    const response = await axios.post(`${APPROVE_PEOPLE}/${data}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const approveCalendarApi = async (data) => {
  try {
    const response = await axios.post(`${APPROVE_Calendar}/${data}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const declinePeopleApi = async (data) => {
  try {
    const response = await axios.post(`${DECLINE_PEOPLE}/${data}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const getCountries = async () => {
  try {
    const response = await axios.get(GET_COUNTRIES);
    if (response.status === 200) {
      return { res: response.data.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const getRegions = async () => {
  try {
    const response = await axios.get(GET_REGIONS);
    if (response.status === 200) {
      return { res: response.data.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const getParties = async () => {
  try {
    const response = await axios.get(GET_PARTIES);
    if (response.status === 200) {
      return { res: response.data.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const getHubs = async () => {
  try {
    const response = await axios.get(GET_HUBS);
    if (response.status === 200) {
      return { res: response.data.data };
    } else return response.data;
  } catch (error) {
    if (error.response) throw error.response.data;
    else throw error.message;
  }
};

export const resetPassword = async (data) => {
  try {
    const response = await axios.post(RESET_PASSWORD, data, {});
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const getExpertise = async () => {
  try {
    const response = await axios.get(GET_EXPERTISE);
    if (response.status === 200) {
      return { res: response.data.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const addExpertise = async (data) => {
  try {
    const response = await axios.post(ADD_EXPERTISE, data);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const deleteExpertise = async (data) => {
  try {
    const response = await axios.delete(DELETE_EXPERTISE, { data: data });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const editExpertise = async (data) => {
  try {
    const response = await axios.post(EDIT_EXPERTISE, data);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const addCountry = async (data) => {
  try {
    const response = await axios.post(ADD_COUNTRY, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const addRegion = async (data) => {
  try {
    const response = await axios.post(ADD_REGION, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const deleteRegion = async (data) => {
  try {
    const response = await axios.delete(DELETE_REGION, { data: data });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const editRegion = async (data) => {
  try {
    const response = await axios.post(EDIT_REGION, data);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const deleteCountry = async (data) => {
  try {
    const response = await axios.delete(DELETE_COUNTRY, { data: data });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const editCountry = async (data) => {
  try {
    const response = await axios.post(EDIT_COUNTRY, data);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const getFeed = async ({ size, page }) => {
  try {
    const response = await axios.get(`${GET_FEED}/${page}/${size}`);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const getPoll = async ({ size, page }) => {
  try {
    const response = await axios.get(`${FEED_GET_POLL}/${page}/${size}`);
    if (response.status === 200) {
      return { res: response.data.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const getPost = async ({ size, page }) => {
  try {
    const response = await axios.get(`${FEED_GET_POST}/${page}/${size}`);
    if (response.status === 200) {
      return { res: response.data.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const addFeedPost = async (data) => {
  try {
    const response = await axios.post(ADD_FEED_POST, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const addFeedPOll = async (data) => {
  try {
    const response = await axios.post(ADD_FEED_POLL, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const addHub = async (data) => {
  try {
    const response = await axios.post(ADD_HUB, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const deleteHub = async (id) => {
  const data = { id: id };
  try {
    const response = await axios.delete(DELETE_HUB, { data: data });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const addAffiliation = async (data) => {
  try {
    const response = await axios.post(ADD_AFFILIATION, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const getMembers = async () => {
  try {
    const response = await axios.get(GET_MEMBERS);
    return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const getMembersById = async (id) => {
  try {
    const response = await axios.get(`${GET_MEMBERS_BY_ID}/${id}`);
    return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const addMiniHub = async (data) => {
  try {
    const response = await axios.post(ADD_MINI_HUB, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const editHubApi = async (data) => {
  try {
    const response = await axios.post(EDIT_HUB, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const editMiniHubApi = async (data) => {
  try {
    const response = await axios.post(EDIT_MINIHUB, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const editSectionApi = async (data) => {
  try {
    const response = await axios.post(EDIT_SECTION, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const removeMembersHubApi = async (data) => {
  try {
    const response = await axios.post(REMOVE_MEMBERS_HUB, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const userDeactivate = async (id) => {
  try {
    const response = await axios.post(`${DEACTIVATE_USER}/${id}`);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const partyDisabledApi = async (id) => {
  try {
    const response = await axios.post(`${PARTY_DISABLED}/${id}`);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const deleteMiniHub = async (id) => {
  const data = { id: id };
  try {
    const response = await axios.delete(DELETE_MINI_HUB, { data: data });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const fetchSingleMiniHub = async (id) => {
  try {
    const response = await axios.get(`${GET_SINGLE_MINI_HUB}/${id}`);
    return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const addSection = async (data) => {
  try {
    const response = await axios.post(ADD_SECTION, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const fetchSingleSection = async (id) => {
  try {
    const response = await axios.get(`${GET_SINGLE_SECTION}/${id}`);
    return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const deleteAffiliation = async (data) => {
  try {
    const response = await axios.delete(DELETE_AFFLIATION, { data: data });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const getSingleAffiliation = async (data) => {
  try {
    const response = await axios.get(GET_SINGLE_PARTY(data));
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const fetchSingleHub = async (id) => {
  try {
    const response = await axios.get(`${GET_SINGLE_HUB}/${id}`);
    return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const editAffiliation = async (data) => {
  try {
    const response = await axios.post(EDIT_AFFILIATION, data);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const fetchManageFlag = async () => {
  try {
    const response = await axios.get(GET_MANAGE_FLAG);
    return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const keepPost = async (data) => {
  try {
    const response = await axios.post(KEEP_POST, data);
    return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const deleteManageFlag = async (data) => {
  try {
    const response = await axios.post(DELETE_MANAGE_FLAG, data);
    return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const fetchRemovedFlag = async () => {
  try {
    const response = await axios.get(GET_REMOVED_FLAG);
    return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const flagReinstate = async (data) => {
  try {
    const response = await axios.post(FLAG_REINSTATE, data);
    return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const addDirectoryAccess = async (data) => {
  try {
    const response = await axios.post(DIRECTORY_ACCESS, data);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const getGeneralNotification = async ({ size, page }) => {
  try {
    const response = await axios.get(
      `${GET_GENERAL_NOTIFICATION}/${page}/${size}`
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const getOraganizationApi = async () => {
  try {
    const response = await axios.get(GET_ORGANIZATION);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const getThinktankApi = async () => {
  try {
    const response = await axios.get(GET_THINKTANK);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const singlePostApi = async (id) => {
  try {
    const response = await axios.get(`${FEEDS_SINGLEPOST}/${id}`);
    if (response.status === 200) {
      return {
        res: response.data,
      };
    } else {
      return response.data;
    }
  } catch (err) {
    if (err.response) {
      throw err.response.data;
    } else {
      throw err.message;
    }
  }
};
export const getNonHubMembersApi = async (id) => {
  try {
    const response = await axios.get(`${NON_HUB_MEMBERS}/${id}`);
    if (response.status === 200) {
      return {
        res: response.data,
      };
    } else {
      return response.data;
    }
  } catch (err) {
    if (err.response) {
      throw err.response.data;
    } else {
      throw err.message;
    }
  }
};
export const addNonHubMembers = async (data) => {
  try {
    const response = await axios.post(ADD_NON_HUB_MEMBERS, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const addGeneralNotification = async (data) => {
  try {
    const response = await axios.post(ADD_GENERAL_NOTIFICATION, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const addResourcesApi = async (data) => {
  try {
    const response = await axios.post(ADD_RESOURCES, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const editResourcesApi = async (data) => {
  try {
    const response = await axios.post(EDIT_RESOURCES, data);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const maintenanceModeApi = async () => {
  try {
    const response = await axios.post(MAINTENANCE_MODE);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const deleteResourcesApi = async (id) => {
  try {
    const response = await axios.delete(`${DELETE_RESOURCES}/${id}`);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

//Starting March 18th, we're transitioning to a new code structure
//where we create custom functions for handling common HTTP request methods like GET, POST, PUT, and DELETE.

// 1 -->> Below is common method for get
export const getMethodFunction = async ({ UrlPath, data, type = "none" }) => {
  try {
    let config = {};

    if (type === "param") {
      config.params = data;
    } else if (type === "body") {
      config = data;
    }

    const response = await axios.get(UrlPath, config);

    if (response.status === 200) {
      return { res: response.data.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

// 2. ---->> Below is common method for post

export const postMethodFunction = async ({
  URLPATH,
  DATA,
  TYPE = "none",
  CONTENTYPE = "application/json",
}) => {
  let config = {};

  if (TYPE === "param") {
    config.params = DATA;
  } else if (TYPE === "body") {
    config = DATA;
  }
  try {
    const response = await axios.post(URLPATH, config, {
      headers: {
        "Content-Type": CONTENTYPE,
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

// 3 --->> Below is common method for put

// try {
//   const response = await Axios.put(EDIT_LISTING, data, {
//     headers: { "Content-Type": "multipart/form-data" },
//   });
//   if (response.status === 200) {
//     return { res: response.data };
//   } else return response.data;
// } catch (err) {
//   if (err.response) throw err.response.data;
//   else throw err.message;
// }

export const putMethodFunction = async ({
  URLPATH,
  DATA,
  TYPE = "none",
  CONTENTYPE = "application/json",
}) => {
  let config = {};

  if (TYPE === "param") {
    config.params = DATA;
  } else if (TYPE === "body") {
    config = DATA;
  }
  try {
    const response = await axios.put(URLPATH, config, {
      headers: {
        "Content-Type": CONTENTYPE,
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
