// import React, { useContext } from 'react';
// import { useState } from 'react';
// import { Grid, TextField } from '@material-ui/core';
// import videoIcon from "../../Assets/images/video.svg"
// import LinkIcon from "../../Assets/images/link.svg"
// import EditorJS from '@editorjs/editorjs';
// import Header from "@editorjs/header"
// import List from "@editorjs/list"
// import Embed from '@editorjs/embed';
// import { addResourcesApi } from '../../Utils/services';
// import { Editor } from "react-draft-wysiwyg";
// import { EditorState, convertToRaw } from 'draft-js';
// import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
// import { useLocation } from 'react-router-dom';
// import { useEffect } from 'react';
// import draftToHtml from 'draftjs-to-html';
// import htmlToDraft from 'html-to-draftjs';
// import { toast } from 'react-toastify';
// import { ModalContext } from '../../Context';




// const SectionWritePostEditer = ({ fetchSectionDetails }) => {

//   const [titleHeading, setTitleHeading] = useState("");
//   const [pdfFile, setPdfFile] = useState('');
//   const [article, setArticle] = useState("");
//   const [mediaType, setMediaType] = useState("file")
//   const [editorState, setEditorState] = useState(EditorState.createEmpty())
//   const [sectionId, setSectionId] = useState('');
//   const location = useLocation();
//   const context = useContext(ModalContext)
//   const { closeModal } = context;

//   useEffect(() => {
//     const { id } = location.state;
//     setSectionId(id)
//   }, []);

//   const handlePdfFile = (val) => {
//     setPdfFile(val);
//     setMediaType("file")
//   }



//   // react-draft-wysiwyg Start
//   const onEditorStateChange = (editorState) => {
//     setEditorState(editorState)
//     let convertedData = draftToHtml(convertToRaw(editorState.getCurrentContent()));
//     setArticle(convertedData)
//   }
//   const embedVideoCallBack = (link) => {
//     link = link.replace("watch?v=", "embed/");
//     link = link.replace("/watch/", "/embed/");
//     link = link.replace("youtu.be/", "youtube.com/embed/");
//     return link
//   }


//   // react-draft-wysiwyg End

//   const handleSubmitSection = async (e) => {
//     e.preventDefault();
//     try {
//       const formData = new FormData();
//       formData.append('heading', titleHeading)
//       formData.append('article', article)
//       formData.append('media', pdfFile)
//       formData.append('section', sectionId)
//       formData.append('mediaType', mediaType)
//       const result = await addResourcesApi(formData);
//       //console.log(result)
//       if (result.res.success) {
//         toast.success(result.res.message)
//         closeModal();
//         fetchSectionDetails();
//       }
//     } catch (error) {
//       toast.error(error)
//     }
//   }

//   return (
//     <>
//       <div className="flex items-center justify-center">
//         <h3 className="title"> Write Post </h3>
//       </div>
//       <form className="w-full" onSubmit={handleSubmitSection}>
//         <div className="grid grid-cols-1 gap-x-24 gap-y-4">
//           <input
//             name="title"
//             id="title"
//             label="Heading"
//             placeholder='Heading...'
//             className="w-full p-3 border border-muteText border-solid focus:outline-none"
//             value={titleHeading}
//             onChange={(e) => setTitleHeading(e.target.value)}
//           />
//         </div>
//         <div className='editorSectionStart'>
//           <Editor
//             editorState={editorState}
//             toolbarClassName="toolbarClassName"
//             wrapperClassName="wrapperClassName"
//             editorClassName="editorClassName"
//             onEditorStateChange={onEditorStateChange}
//             toolbar={{
//               embedded: {
//                 embedCallback: embedVideoCallBack
//               }
//             }}
//           />
//         </div>
//         {/* <Grid item md={2}>
//           <div className="relative w-auto overflow-hidden">
//             <h4 className='mt-4 text-sidenav font-bold'>Add Video(s)</h4>
//             <TextField
//               id="profilephoto"
//               label="Add a Video"
//               // value={VideoFile.name || ""}
//               className="w-full text-xs"
//             />

//             <input
//               type="file"
//               accept="video/*"
//               className="z-50 opacity-0 absolute bottom-0 right-0 w-50"
//               name="video-file"
//             // onChange={(e) => handleVideoFile(e.target.files[0])}
//             />
//             <div className="floating-box" >+</div>
//           </div>
//           <div className="media-box mt-2">
//             <img src={videoIcon} alt="" />
//             Videos
//           </div>
//         </Grid> */}
//         <Grid item md={2}>
//           <div className="relative w-auto overflow-hidden">
//             <h4 className='mt-4 text-sidenav font-bold'>Attach Files</h4>
//             <TextField
//               id="profilephoto"
//               label="Add a PDF File"
//               value={pdfFile.name || ""}
//               className="w-full text-xs"
//             />

//             <input
//               type="file"
//               accept="application/pdf, application/vnd.ms-excel"
//               className="z-50 opacity-0 absolute bottom-0 right-0 w-50"
//               name="pdf-file"
//               onChange={(e) => handlePdfFile(e.target.files[0])}
//             />
//             <div className="floating-box" >+</div>
//           </div>
//           <div className="media-box mt-2">
//             <img src={LinkIcon} alt="" />
//             File
//           </div>
//         </Grid>
//         <div className="flex justify-center mt-10">
//           <button className="button-1 mx-auto" type='submit'>
//             POST
//           </button>
//         </div>
//       </form>
//     </>
//   );
// };

// export default SectionWritePostEditer;

import React from "react";


const SectionWritePostEditer = () => {
  return (
    <div>
      working 
    </div>
  )
}

export default SectionWritePostEditer;